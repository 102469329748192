<hr />
<p class="p-black" translate>search_by_bale.laboratory_data</p>
<div class="row">
  <div class="col">
    <div class="my-google-map">
      <rastreabilidade-map
        [latitude]="laboratoryData?.addressHviDto?.latitude"
        [longitude]="laboratoryData?.addressHviDto?.longitude"
      >
      </rastreabilidade-map>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <div class="col">
        <label translate> search_by_bale.fantasy_name </label>
        <p>{{ laboratoryData.fantasyName }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label translate> search_by_bale.address </label>
        <p>
          {{ laboratoryData.addressHviDto.description }},
          {{ laboratoryData.addressHviDto.city }},
          {{ laboratoryData.addressHviDto.district }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <a
          class="btn btn-outline-primary button-google-maps-responsive"
          target="_blank"
          href="{{ googleMapsUrl }}"
          translate
        >
          search_by_bale.localization
        </a>
      </div>
    </div>
  </div>
</div>
