<card>
  <thead>
    <card-header>
      <div class="container ajust__title">
        <div class="row header-content">
          <div translate>
            <fa-icon
              class="arrow__left col-md-3"
              [icon]="['fas', 'arrow-left']"
              [routerLink]="['/consulta-por-lote']"
            ></fa-icon>
            burden_requests.view_berden_request_title
          </div>
          <p class="subtitle">
            <span translate>burden_requests.burden_request_code</span> -
            <strong> {{ burden?.code }}</strong>
          </p>
        </div>
      </div>
    </card-header>

    <alert type="secondary" [hasIcon]="false">
      <div class="col-lg-12 ajuste-container">
        <div class="card text-dark bg-light my-3 info-content">
          <div class="card-body row">Total<br /></div>
          <div class="ajust-number">{{ burden?.total }}</div>
        </div>
        <div class="card text-dark bg-light my-3 info-content">
          <div class="card-body row">
            {{ "burden_requests.messages.approved_burdens" | translate }}<br />
          </div>
          <div class="ajust-number">{{ burden?.approvedQuantity }}</div>
        </div>
        <div class="card text-dark bg-light my-3 info-content">
          <div class="card-body row">
            {{ "burden_requests.messages.disapproved_burdens" | translate
            }}<br />
          </div>
          <div class="ajust-number">{{ burden?.disapprovedQuantity }}</div>
        </div>
        <div class="card text-dark bg-light my-3 info-content">
          <div class="card-body row">
            {{ "burden_requests.messages.pending_burdens" | translate }}<br />
          </div>
          <div class="ajust-number">{{ burden?.pendingQuantity }}</div>
        </div>
        <div class="card text-dark bg-light my-3 info-content">
          <div
            class="card-body row"
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: center;
              gap: 3px;
            "
          >
            <span>
              {{ "burden_requests.messages.no_sbrhvi" | translate }}
            </span>
            <rastreabilidade-tooltip-total
              [text]="'burden_requests.messages.info_sbrhvi' | translate"
              [value]=""
              [isList]="false"
            ></rastreabilidade-tooltip-total>
          </div>
          <div class="ajust-number">
            {{ burden?.noSbrHvi?.quantityBurden || 0 }}
          </div>
        </div>
        <div class="card text-dark bg-light my-3 info-content">
          <div class="card-body row">
            {{ "burden_requests.messages.invalid_codes" | translate }}<br />
          </div>
          <div class="ajust-number">{{ burden?.invalidBurdens || 0 }}</div>
        </div>
        <div id="linha-vertical"></div>
        <div class="card text-dark bg-light my-3 info-content">
          <div class="card-body row" translate>
            burden_requests.view_request.map_certificate
            <br />
          </div>
          <div class="ajust-number">{{ burden?.certifiedBurdenMapa || 0 }}</div>
        </div>
        <div class="card text-dark bg-light my-3 info-content">
          <div class="card-body row" translate>
            burden_requests.view_request.not_certified_map
            <br />
          </div>
          <div class="ajust-number">
            {{ burden?.notCertifiedBurdenMapa || 0 }}
          </div>
        </div>
      </div>
    </alert>

    <div class="container" *ngIf="burden && burden.code && burden.burdens">
      <div *ngIf="burden?.burdens.length != 0" class="table-responsive">
        <table class="table no-header">
          <thead>
            <tr>
              <th translate>burden_requests.view_request.trading_detail</th>
              <th translate>burden_requests.view_request.quantity_burdens</th>
              <th translate>burden_requests.view_request.producer_trading</th>
              <th translate>burden_requests.view_request.date_opinion</th>
              <th translate>burden_requests.view_request.abrapa_certificate</th>
              <th translate>burden_requests.view_request.map_certificate</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let burdenLoop of burden?.burdens; let i = index"
            >
              <ng-container *ngIf="burdenLoop.trading">
                <tr>
                  <td>
                    <a
                      data-toggle="collapse"
                      href="#collapseExample{{ i }}"
                      role="button"
                      aria-expanded="false"
                      [attr.aria-controls]="'collapseExample' + i"
                      class="toggle"
                      (click)="toggleCollapse(i, $event)"
                      ><fa-icon
                        *ngIf="!collapsed[i]"
                        [icon]="['fas', 'plus']"
                      ></fa-icon
                      ><fa-icon
                        *ngIf="collapsed[i]"
                        [icon]="['fas', 'minus']"
                      ></fa-icon
                    ></a>
                  </td>
                  <td>{{ burdenLoop.trading.quantityBurden }}</td>
                  <td>{{ burdenLoop.trading.name }}</td>
                  <td>
                    {{ burdenLoop.trading.dateOpinion | date : "dd/MM/yyyy" }}
                  </td>
                  <td>
                    <div
                      class="row btn__pdf__download d-flex justify-content-center"
                      (click)="
                        downloadArquivo(burdenLoop.trading.tradeId, true)
                      "
                      *ngIf="
                        showDownload(burdenLoop.trading.status);
                        else notAproved
                      "
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <svg
                        width="25"
                        height="32"
                        viewBox="0 0 25 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <rect width="25" height="32" fill="url(#pattern0)" />
                          <pattern
                            id="pattern0"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                          >
                            <use
                              xlink:href="#image0_95_2991"
                              transform="matrix(0.00125122 0 0 0.000977517 -0.0211339 0)"
                            />
                          </pattern>
                          <image
                            id="image0_95_2991"
                            width="833"
                            height="1023"
                            xlink:href="../assets/icons/icon-pdf.png"
                          />
                          >
                        </defs>
                      </svg>
                    </div>
                    <ng-template #notAproved>
                      <div class="tooltip-column">
                        <fa-icon
                          [icon]="['fas', 'circle']"
                          [class]="getColorClass(burdenLoop.trading.status.key)"
                        ></fa-icon>
                        <rastreabilidade-tooltip-total
                          [text]="burdenLoop.trading.tooltip"
                          [value]="burdenLoop.trading.status.value"
                        >
                        </rastreabilidade-tooltip-total>
                      </div>
                    </ng-template>
                  </td>
                  <td>
                    <a
                      [href]="burden.linkCertificationMapa"
                      target="_blank"
                      class="link"
                      *ngIf="
                        isValidHttpUrl(burden.linkCertificationMapa);
                        else noCertification
                      "
                    >
                      <fa-icon
                        class="link-cert-map"
                        [icon]="['fas', 'link']"
                      ></fa-icon>
                      {{ burden.identificacaoMapa }}
                    </a>
                    <ng-template #noCertification>
                      <fa-icon
                        [class]="getColorClass(burden.iconMapa)"
                        [icon]="['fas', 'circle']"
                      ></fa-icon>
                      {{ burden.identificacaoMapa }}
                    </ng-template>
                  </td>
                </tr>
                <ng-container *ngIf="collapsed[i]">
                  <tr
                    class="collapse trading-burdens"
                    [ngClass]="{ show: collapsed[i] }"
                    id="collapseExample{{ i }}"
                    *ngFor="let burdenInternal of burdenLoop.burdens"
                  >
                    <td>--</td>
                    <td>{{ burdenInternal.quantityBurden }}</td>
                    <td>
                      {{ burdenInternal.producerOrigin }} /
                      {{ burdenInternal.producer }}
                    </td>
                    <td>
                      {{ burdenInternal.dateOpinion | date : "dd/MM/yyyy" }}
                    </td>
                    <td>
                      <div
                        class="row btn__pdf__download"
                        *ngIf="
                          showDownload(burdenInternal.status);
                          else notAproved
                        "
                      >
                        <app-download
                          type="cloud-download-alt"
                          (onClick)="
                            downloadArquivo(
                              burdenInternal.originProducerId,
                              burdensTradingSelected?.trading != null,
                              burdensTradingSelected?.trading?.tradeId
                            )
                          "
                        >
                        </app-download>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div
                          (click)="
                            downloadPDF(
                              burdenInternal.originProducerId,
                              burdensTradingSelected?.trading != null,
                              burdensTradingSelected?.trading?.tradeId
                            )
                          "
                        >
                          <svg
                            width="25"
                            height="32"
                            viewBox="0 0 25 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                          >
                            <rect
                              width="25"
                              height="32"
                              fill="url(#pattern0)"
                            />
                            <defs>
                              <pattern
                                id="pattern0"
                                patternContentUnits="objectBoundingBox"
                                width="1"
                                height="1"
                              >
                                <use
                                  xlink:href="#image0_95_2991"
                                  transform="matrix(0.00125122 0 0 0.000977517 -0.0211339 0)"
                                />
                              </pattern>
                              <image
                                id="image0_95_2991"
                                width="833"
                                height="1023"
                                xlink:href="../assets/icons/icon-pdf.png"
                              />
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <ng-template #notAproved>
                        <div class="tooltip-column">
                          <fa-icon
                            [icon]="['fas', 'circle']"
                            [class]="getColorClass(burdenInternal.status.key)"
                          ></fa-icon>
                          <rastreabilidade-tooltip-total
                            [text]="burdenInternal.tooltip"
                            [value]="burdenInternal.status.value"
                          >
                          </rastreabilidade-tooltip-total>
                        </div>
                      </ng-template>
                    </td>
                    <td>
                      <a
                        [href]="burden.linkCertificationMapa"
                        target="_blank"
                        class="link"
                        *ngIf="
                          isValidHttpUrl(burden.linkCertificationMapa);
                          else noCertification
                        "
                      >
                        <fa-icon
                          class="link-cert-map"
                          [icon]="['fas', 'link']"
                        ></fa-icon>
                        {{ burden.identificacaoMapa }}
                      </a>
                      <ng-template #noCertification>
                        <fa-icon
                          [class]="getColorClass(burden.iconMapa)"
                          [icon]="['fas', 'circle']"
                        ></fa-icon>
                        {{ burden.identificacaoMapa }}
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!burdenLoop.trading">
                <tr
                  class="color__table__secundary"
                  *ngFor="let burdenInternal of burdenLoop.burdens"
                >
                  <td>--</td>
                  <td>{{ burdenInternal.quantityBurden }}</td>
                  <td>
                    {{ burdenInternal.producerOrigin }} /
                    {{ burdenInternal.producer }}
                  </td>
                  <td>
                    {{ burdenInternal.dateOpinion | date : "dd/MM/yyyy" }}
                  </td>
                  <td>
                    <div
                      class="row btn__pdf__download"
                      *ngIf="
                        showDownload(burdenInternal?.status);
                        else notAproved
                      "
                    >
                      <app-download
                        type="cloud-download-alt"
                        (onClick)="
                          downloadArquivo(
                            burdenInternal.originProducerId,
                            burdenLoop?.trading != null,
                            burdenLoop?.trading?.tradeId
                          )
                        "
                      >
                      </app-download>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div
                        (click)="
                          downloadPDF(
                            burdenInternal.originProducerId,
                            burdenLoop?.trading != null,
                            burdenLoop?.trading?.tradeId
                          )
                        "
                      >
                        <svg
                          width="25"
                          height="32"
                          viewBox="0 0 25 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                          <rect width="25" height="32" fill="url(#pattern0)" />
                          <defs>
                            <pattern
                              id="pattern0"
                              patternContentUnits="objectBoundingBox"
                              width="1"
                              height="1"
                            >
                              <use
                                xlink:href="#image0_95_2991"
                                transform="matrix(0.00125122 0 0 0.000977517 -0.0211339 0)"
                              />
                            </pattern>
                            <image
                              id="image0_95_2991"
                              width="833"
                              height="1023"
                              xlink:href="../assets/icons/icon-pdf.png"
                            />
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <ng-template #notAproved>
                      <div class="tooltip-column">
                        <fa-icon
                          [icon]="['fas', 'circle']"
                          [class]="getColorClass(burdenInternal.status.key)"
                        ></fa-icon>
                        <rastreabilidade-tooltip-total
                          [text]="burdenInternal.tooltip"
                          [value]="burdenInternal.status.value"
                        >
                        </rastreabilidade-tooltip-total>
                      </div>
                    </ng-template>
                  </td>
                  <td>
                    <a
                      [href]="this.burden.linkCertificationMapa"
                      target="_blank"
                      class="link"
                      *ngIf="
                        isValidHttpUrl(burden.linkCertificationMapa);
                        else noCertification
                      "
                    >
                      <fa-icon
                        class="link-cert-map"
                        [icon]="['fas', 'link']"
                      ></fa-icon>
                      {{ burden.identificacaoMapa }}
                    </a>
                    <ng-template #noCertification>
                      <fa-icon
                        [class]="getColorClass(burden.iconMapa)"
                        [icon]="['fas', 'circle']"
                      ></fa-icon>
                      {{ burden.identificacaoMapa }}
                    </ng-template>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf="burden?.noSbrHvi" class="table-responsive">
        <table class="table no-header">
          <thead class="color__table">
            <tr>
              <th translate>burden_requests.view_request.quantity_burdens</th>
              <th translate>burden_requests.view_request.status</th>
              <th translate>burden_requests.view_request.file</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ burden.noSbrHvi.quantityBurden }}</td>
              <td>{{ burden.noSbrHvi.status.value }}</td>
              <td>
                <p class="d-flex justify-content-center">
                  <app-download
                    type="cloud-download-alt"
                    label="{{
                      'burden_requests.view_request.download' | translate
                    }}"
                    (onClick)="
                      downloadOutrosArquivo(burden.noSbrHvi.status.key)
                    "
                  >
                  </app-download>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="d-flex justify-content-center non-sbrhvi" translate>
          search_by_bale.hvi_not_available
        </p>
      </div>
    </div>
  </thead>
</card>

<div class="card-bottom">
  <card>
    <div class="card-body info-card">
      <fa-icon style="color: #47d66f" [icon]="['fas', 'check-circle']"></fa-icon
      >&nbsp;&nbsp;&nbsp;
      <div translate>burden_requests.messages.non_profit_organization</div>
    </div>
  </card>
</div>
<card>
  <div class="card-body info-card">
    <fa-icon style="color: #47d66f" [icon]="['fas', 'check-circle']"></fa-icon
    >&nbsp;&nbsp;&nbsp;
    <div translate>burden_requests.messages.responsible_organization_map</div>
  </div>
</card>

<modal #modalDetalhesTrading [modalConfig]="modalConfigDetalhesTrading">
  <div class="" *ngIf="burdensTradingSelected">
    <div
      *ngIf="burdensTradingSelected?.burdens.length != 0"
      class="table-responsive"
    >
      <table class="table no-header">
        <thead>
          <tr>
            <th translate>burden_requests.view_request.quantity_burdens</th>
            <th translate>burden_requests.view_request.producer_trading</th>
            <th translate>burden_requests.view_request.date_opinion</th>
            <th translate>burden_requests.view_request.abrapa_certificate</th>
            <th translate>burden_requests.view_request.map_certificate</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let burdenInternal of burdensTradingSelected.burdens">
            <td>{{ burdenInternal.quantityBurden }}</td>
            <td>
              {{ burdenInternal.producerOrigin }} /
              {{ burdenInternal.producer }}
            </td>
            <td>
              {{ burdenInternal.dateOpinion | date : "dd/MM/yyyy" }}
            </td>
            <td>
              <div
                class="row btn__pdf__download"
                *ngIf="showDownload(burdenInternal.status); else notAproved"
              >
                <app-download
                  type="cloud-download-alt"
                  (onClick)="
                    downloadArquivo(
                      burdenInternal.originProducerId,
                      burdensTradingSelected?.trading != null,
                      burdensTradingSelected?.trading?.tradeId
                    )
                  "
                >
                </app-download>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div
                  (click)="
                    downloadPDF(
                      burdenInternal.originProducerId,
                      burdensTradingSelected?.trading != null,
                      burdensTradingSelected?.trading?.tradeId
                    )
                  "
                >
                  <svg
                    width="25"
                    height="32"
                    viewBox="0 0 25 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <rect width="25" height="32" fill="url(#pattern0)" />
                    <defs>
                      <pattern
                        id="pattern0"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlink:href="#image0_95_2991"
                          transform="matrix(0.00125122 0 0 0.000977517 -0.0211339 0)"
                        />
                      </pattern>
                      <image
                        id="image0_95_2991"
                        width="833"
                        height="1023"
                        xlink:href="../assets/icons/icon-pdf.png"
                      />
                    </defs>
                  </svg>
                </div>
              </div>
              <ng-template #notAproved>
                <div class="tooltip-column">
                  <fa-icon
                    [icon]="['fas', 'circle']"
                    [class]="getColorClass(burdenInternal.status.key)"
                  ></fa-icon>
                  <rastreabilidade-tooltip-total
                    [text]="burdenInternal.tooltip"
                    [value]="burdenInternal.status.value"
                  >
                  </rastreabilidade-tooltip-total>
                </div>
              </ng-template>
            </td>
            <td>
              <a
                [href]="burden.linkCertificationMapa"
                target="_blank"
                class="link"
                *ngIf="
                  isValidHttpUrl(burden.linkCertificationMapa);
                  else noCertification
                "
              >
                <fa-icon
                  class="link-cert-map"
                  [icon]="['fas', 'link']"
                ></fa-icon>
                {{ burden.identificacaoMapa }}
              </a>
              <ng-template #noCertification>
                <fa-icon
                  [class]="getColorClass(burden.iconMapa)"
                  [icon]="['fas', 'circle']"
                ></fa-icon>
                {{ burden.identificacaoMapa }}
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</modal>
