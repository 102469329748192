<card class="mb-5">
  <card-header>
    <div class="container text-responsive" translate>
      register.access_request_registration_form
    </div>
  </card-header>

  <card-body>
    <div class="container">
      <h6 class="subtitle__register text-responsive" translate>
        MANAGE_ACCCESS_REQUEST.EDIT.DATA_INFORMATION
      </h6>
      <br />
      <div class="row requester-data-row">
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.full_name</label>
          <p>{{ requester?.name }}</p>
        </div>
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.cpf_passport</label>
          <p>{{ requester?.cpf_passport }}</p>
        </div>
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.corporative_email</label>
          <p>{{ requester?.corporative_email }}</p>
        </div>
      </div>
      <div class="row requester-data-row">
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.birth_date</label>
          <p>{{ requester?.birth_date | date : "dd/MM/yyyy" }}</p>
        </div>
      </div>
      <h6 class="subtitle__register text-responsive" translate>
        MANAGE_ACCCESS_REQUEST.EDIT.DATA_COMPANY
      </h6>
      <br />
      <div class="row requester-data-row">
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.company_name</label>
          <p>{{ requester?.company_name }}</p>
        </div>
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.role</label>
          <p>{{ requester?.role }}</p>
        </div>
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.company_category</label>
          <p>{{ companyCategoryName }}</p>
        </div>
      </div>
      <div class="row requester-data-row">
        <div class="col-md-4">
          <label translate
            >manage_acccess_request.edit.corporative_cellphone</label
          >
          <p>
            {{ requester?.corporative_cellphone | mask : "+000000000000000" }}
          </p>
        </div>
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.company_website</label>
          <p>{{ requester?.company_website }}</p>
        </div>
        <div class="col-md-4">
          <label translate
            >manage_acccess_request.edit.corporative_telephone</label
          >
          <p>
            {{ requester?.corporative_telephone | mask : "+000000000000000" }}
          </p>
        </div>
      </div>
      <div class="row requester-data-row">
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.company_address</label>
          <p>{{ requester?.company_address }}</p>
        </div>
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.city</label>
          <p>{{ requester?.city }}</p>
        </div>
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.country</label>
          <p>{{ requester?.country }}</p>
        </div>
      </div>
      <div class="row requester-data-row">
        <div class="col-md-4">
          <label translate>manage_acccess_request.edit.zip_code</label>
          <p>{{ requester?.zip_code }}</p>
        </div>
        <div class="col-md-4" *ngIf="isCompanyBrazilian">
          <label>CNPJ</label>
          <p>{{ requester?.cnpj }}</p>
        </div>
      </div>
      <div
        *ngIf="
          identifiedSystems?.length == 0 || identifiedSystems?.length == null
        "
      >
        <hr />
        <h6 class="subtitle__register text-responsive" translate>
          manage_acccess_request.edit.identified_systems
        </h6>
        <p class="user_is_not_registered" translate>
          manage_acccess_request.edit.user_is_not_registered
        </p>
      </div>
      <div *ngIf="identifiedSystems?.length > 0">
        <hr />
        <h6 class="subtitle__register text-responsive" translate>
          manage_acccess_request.edit.identified_systems
        </h6>
      </div>
      <div
        class="row requester-data-row"
        *ngIf="
          identifiedSystems?.length != 0 || identifiedSystems?.length != null
        "
      >
        <div class="col-md-4">
          <label>
            <ul>
              <li *ngFor="let system of identifiedSystems">
                {{ system?.description }}
              </li>
            </ul>
          </label>
        </div>
      </div>
      <hr />
      <div class="row requester-data-row">
        <div
          class="col-md-12 requester-data-row"
          *ngIf="
            requester?.name_complement ||
            requester?.corporative_telephone_complement ||
            requester?.corporative_email_complement ||
            (requester?.company_bond_file && requester?.company_bond_file_name)
          "
        >
          <h6 class="subtitle__register text-responsive" translate>
            manage_acccess_request.edit.additional_information
          </h6>
        </div>
        <div class="col-md-4" *ngIf="requester?.name_complement">
          <label translate>register.full_name</label>
          <p>{{ requester?.name_complement }}</p>
        </div>
        <div
          class="col-md-4"
          *ngIf="requester?.corporative_telephone_complement"
        >
          <label translate>register.corporate_phone</label>
          <p>
            {{
              requester?.corporative_telephone_complement
                | mask : "+000000000000000"
            }}
          </p>
        </div>
        <div class="col-md-4" *ngIf="requester?.corporative_email_complement">
          <label translate>register.corporate_email</label>
          <p>{{ requester?.corporative_email_complement }}</p>
        </div>
      </div>
      <div
        class="row requester-data-row"
        *ngIf="
          requester?.company_bond_file && requester?.company_bond_file_name
        "
      >
        <div class="col-md-12">
          <hr />
          <h6 class="subtitle__register text-responsive" translate>
            manage_acccess_request.edit.bond_file
          </h6>

          <label class="normal__label download">
            <a [href]="requester?.company_bond_file" target="_blank">
              <div class="col-md-12">
                <app-download
                  type="file-pdf"
                  [direction]="'row'"
                  [label]="requester?.company_bond_file_name"
                  size="30px"
                >
                </app-download>
              </div>
            </a>
          </label>
        </div>
      </div>
      <br />
      <br />

      <div
        class="row requester-data-row"
        *ngIf="
          !accessRequestStatus &&
          statusRequest !== 'Aprovado' &&
          statusRequest !== 'Reprovado'
        "
      >
        <div
          class="col-md-10"
          *ngIf="
            (!requester?.name_complement &&
              !requester?.corporative_telephone_complement &&
              !requester?.corporative_email_complement) ||
            (!requester?.company_bond_file &&
              !requester?.company_bond_file_name)
          "
        >
          <form
            class="form-row d-flex justify-content-between"
            [formGroup]="fbRequestInformation"
          >
            <div
              class="custom-control custom-checkbox"
              *ngIf="
                !requester?.name_complement &&
                !requester?.corporative_telephone_complement &&
                !requester?.corporative_email_complement
              "
            >
              <input
                type="checkbox"
                formControlName="reference_information"
                class="custom-control-input"
                id="reference_information"
              />
              <label
                class="custom-control-label"
                for="reference_information"
                translate
                >manage_acccess_request.edit.reference_information</label
              >
            </div>
            <div
              class="custom-control custom-checkbox"
              *ngIf="
                !requester?.company_bond_file &&
                !requester?.company_bond_file_name
              "
            >
              <input
                type="checkbox"
                formControlName="bond_letter"
                class="custom-control-input"
                id="bond_letter"
              />
              <label class="custom-control-label" for="bond_letter" translate
                >register.letter_of_employment_in_the_company</label
              >
            </div>
          </form>
        </div>
      </div>
      <div class="lighter__label">
        <div class="row" *ngIf="statusRequest === 'Aprovado'">
          <div class="col-md-6">
            <label translate>manage_acccess_request.edit.status</label>
            <label>{{ statusRequest }}</label>
          </div>
          <div class="col-md-6">
            <label translate>manage_acccess_request.edit.approval_date</label>
            <p>{{ requester?.statusDate | date : "dd/MM/yyyy" }}</p>
          </div>
        </div>

        <div class="row" *ngIf="statusRequest === 'Reprovado'">
          <div class="col-md-6">
            <div class="col-md-12">
              <label translate>manage_acccess_request.edit.status</label>
              <label>{{ statusRequest }}</label>
            </div>
            <div class="col-md-12">
              <label translate
                >manage_acccess_request.edit.disapproval_date</label
              >
              <p>{{ requester?.statusDate | date : "dd/MM/yyyy" }}</p>
            </div>
          </div>
          <div class="col-md-6">
            <label translate>manage_acccess_request.edit.reason</label>
            <p>{{ requester?.reason_failure }}</p>
          </div>
        </div>
        <div *ngIf="accessRequestStatus">
          <div class="row">
            <div class="col-md-6">
              <label translate>manage_acccess_request.edit.status</label><br />
              <label>{{ labelStatus }}</label>
            </div>
            <div class="col-md-6">
              <label> {{ labelDateStatus }} </label><br />
              <p>{{ requester?.statusDate | date : "dd/MM/yyyy" }}</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <rastreabilidade-history
        [requestId]="id"
        [isRequester]="false"
        #history
      ></rastreabilidade-history>
    </div>
  </card-body>
</card>
<br />
<rastreabilidade-history-form-card
  [requestId]="id"
  (eventEmitter)="history.reloadHistory()"
>
</rastreabilidade-history-form-card>
<br />
<div class="text-right mb-5 buttons-content">
  &nbsp;
  <button
    class="btn btn-outline-primary col-md-5"
    [ngClass]="{ disabled: disableAdditionalInformation() }"
    (click)="submitAdditionalInformation()"
    [disabled]="!isAprovedOrRepdroved || !validateForm"
    translate
  >
    manage_acccess_request.edit.btn_request_aditional
  </button>
  &nbsp;
  <button
    class="btn btn-danger"
    [ngClass]="{ disabled: disableDisaApprove() }"
    (click)="disapprove()"
    [disabled]="!isAprovedOrRepdroved || validateForm"
    translate
  >
    manage_acccess_request.edit.btn_disapprove
  </button>
  &nbsp;
  <button
    class="btn btn-success"
    [ngClass]="{ disabled: disableApprove() }"
    (click)="approve()"
    [disabled]="!isAprovedOrRepdroved || validateForm"
    translate
  >
    manage_acccess_request.edit.btn_approve
  </button>
</div>
