<div class="container">
  <h5 class="title__register" translate>register.sai_traceability</h5>
  <br />
  <div class="container container__form__register">
    <h6 class="subtitle__register" translate>
      register.access_request_registration_form
    </h6>
    <br />
    <form [formGroup]="requestAccessForm">
      <div class="form-group col-md-5">
        <label for="corporativeEmail" class="lbl__thinner"
          >{{ "register.corporate_email" | translate }} <required> </required
        ></label>
        <input
          type="email"
          formControlName="email"
          [ngClass]="{
            'is-invalid':
              requestAccessForm.controls.email.errors &&
              requestAccessForm.controls.email.touched
          }"
          class="form-control form__input"
          id="corporativeEmail"
          [placeholder]="placeholderEmailExample"
        />
        <div id="corporativeEmail" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <hr />
      <div class="form-group group__btns__register">
        <button class="btn btn__default" [routerLink]="['/']" translate>
          generic.buttons.cancel</button
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <button
          class="btn btn__primary"
          (click)="enviar()"
          [disabled]="!requestAccessForm.valid"
          translate
        >
          generic.buttons.send
        </button>
      </div>
    </form>
  </div>
</div>
