<card>
  <card-header>
    <div class="container">
      <div class="header-batch">
        <div>{{ "burden_requests.title" | translate }}</div>
        <div>
          <app-button [routerLink]="['nova-solicitacao']" type="primary"
            >{{ "burden_requests.new_request" | translate }}
          </app-button>
        </div>
      </div>
    </div>
  </card-header>
  <card-body>
    <div class="container table-responsive">
      <table class="table no-header">
        <thead>
          <tr>
            <th scope="col" translate>burden_requests.code</th>
            <th scope="col" translate>burden_requests.total</th>
            <th scope="col" translate>burden_requests.request_date</th>
            <th scope="col" translate>burden_requests.status</th>
            <th width="100px"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let burdenRequest of burdenRequests">
            <td>{{ burdenRequest.code }}</td>
            <td>
              <rastreabilidade-tooltip-total
                [text]="burdenRequest.totalDetailed"
                [value]="burdenRequest.total"
                [isList]="true"
              ></rastreabilidade-tooltip-total>
            </td>
            <td>{{ burdenRequest.dateRequest | date }}</td>
            <td translate>
              <fa-icon
                [icon]="['fas', 'circle']"
                [class]="
                  getColorClass(burdenRequest.requestQueryBurdenBatchEnum)
                "
              >
              </fa-icon>
              {{ burdenRequest.status }}
            </td>
            <td class="link">
              <a
                [routerLink]="['/consulta-por-lote/' + burdenRequest.id]"
                translate
                >generic.visualize_button</a
              >
            </td>
          </tr>
          <tr *ngIf="burdenRequests.length === 0">
            <td translate>generic.data_not_found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col p-0">
        <div class="flex-lg-row pagination-content">
          <div class="show-page-content">
            <div class="label-content">
              <label translate>GENERIC.PAGINATION.LABEL.SHOWING</label>
              <label> {{ currentPageSize }}</label>
            </div>
            <div class="label-content">
              <label translate>GENERIC.PAGINATION.LABEL.REGISTERS</label>
              <label> {{ collectionSize }}</label>
            </div>
          </div>
          <ngb-pagination
            [collectionSize]="collectionSize"
            [(page)]="page"
            [maxSize]="width > 767 ? 5 : 3"
            [rotate]="true"
            [pageSize]="pageSize"
            (pageChange)="refreshPage()"
            class="d-flex d-lg-block justify-content-center"
          >
          </ngb-pagination>
          <div class="d-flex justify-content-center align-items-center">
            <select
              class="custom-select"
              style="width: auto"
              [(ngModel)]="pageSize"
              (ngModelChange)="refreshPage()"
            >
              <option [ngValue]="10">
                10
                <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
              </option>
              <option [ngValue]="25">
                25
                <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
              </option>
              <option [ngValue]="50">
                50
                <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
              </option>
              <option [ngValue]="100">
                100
                <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </card-body>
</card>
