import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RequesterReportService } from 'src/app/core/services/requester-report.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Base64ToBlob } from 'src/app/shared/utils/base64ToBlob';
import * as FileSaver from 'file-saver';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { BurderDownloadRequest } from 'src/app/shared/models/burden/burder-download-request.model';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'rastreabilidade-requester-report',
  templateUrl: './requester-report.component.html',
  styleUrls: ['./requester-report.component.scss'],
})
export class RequesterReportComponent implements OnInit {
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  currentPageSize = 10;
  isLoading: boolean = false;
  requesterReports: any[] = [];
  searchedBurden: number = 0;
  bsConfig?: Partial<BsDatepickerConfig>;

  formFilter = this.fb.group({
    queryType: [0],
    statusRequest: [''],
    startPeriod: [null],
    endPeriod: [null],
    periodQueryDate: [null],
    validateDate: [null],
    initDateInput: [''],
    finishDateInput: [''],
  });

  width: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.width = window.innerWidth;
  }

  constructor(
    private service: RequesterReportService,
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private dateLocale: BsLocaleService
  ) {
    this.dateLocale.use(localStorage.getItem('locale'));
  }

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });
    this.width = window.innerWidth;

    this.formFilter.get('initDateInput').valueChanges.subscribe((ret) => {
      let date =
        ret != ''
          ? `${ret?.getFullYear()}-${('0' + (ret?.getMonth() + 1)).slice(
              -2
            )}-${('0' + ret?.getDate()).slice(-2)}`
          : '';
      this.formFilter.controls.startPeriod.setValue(date);
    });

    this.formFilter.get('finishDateInput').valueChanges.subscribe((ret) => {
      let date =
        ret != ''
          ? `${ret?.getFullYear()}-${('0' + (ret?.getMonth() + 1)).slice(
              -2
            )}-${('0' + ret?.getDate()).slice(-2)}`
          : '';
      this.formFilter.controls.endPeriod.setValue(date);
    });

    this.refreshPage();
  }

  refreshPage(): void {
    this.showSpinner();
    if (!this.validateDate()) return;
    this.getAllReports();
  }

  showSpinner() {
    this.isLoading = true;
    this.spinnerService.show();
  }

  hideSpinner() {
    this.isLoading = false;
    this.spinnerService.hide();
  }

  clearFilter() {
    this.formFilter.controls.queryType.setValue(0);
    this.formFilter.controls.statusRequest.setValue('');
    this.formFilter.controls?.initDateInput?.setValue('');
    this.formFilter.controls?.finishDateInput?.setValue('');
    this.formFilter.controls.startPeriod.setValue(null);
    this.formFilter.controls.endPeriod.setValue(null);
    this.formFilter.controls.periodQueryDate.setValue(null);
    this.formFilter.controls.validateDate.setValue(null);

    this.refreshPage();
  }

  validateDate(): boolean {
    if (
      this.formFilter.controls.startPeriod.value >
      this.formFilter.controls.endPeriod.value
    ) {
      this.toastService.showToast(
        ToastTypeEnum.DANGER,
        'Erro',
        `Data de início do período maior que a data fim.`
      );
      this.spinnerService.hide();
      return false;
    }

    return true;
  }

  getAllReports() {
    this.service
      .getAll({
        ...this.formFilter.getRawValue(),
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      .subscribe(
        (res) => {
          this.requesterReports = res.data.data;
          this.currentPageSize = res.data.data.length;
          this.collectionSize = res.data.totalItems;
          this.searchedBurden = res.data.totalBurdenConsulted;
          this.hideSpinner();
        },
        (errors) => {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            `${errors}`
          );
        }
      );
  }

  downloadArquivoCsv() {
    this.service
      .downloadArquivoCsv({
        ...this.formFilter.getRawValue(),
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      .subscribe((res) => {
        if (res.success && res.data) {
          const blob = new Base64ToBlob().convertToBlob(res.data, 'text/csv');
          FileSaver.saveAs(blob, `relatorio`);
        } else {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            'Erro ao baixar o certificado'
          );
        }
        this.spinnerService.hide();
      });
  }

  downloadTxtFile(request: any) {
    this.generateTxtFile(request);
  }

  downloadPdfFile(request: any) {
    this.generatePdfFile(request);
  }

  generateTxtFile(request: BurderDownloadRequest) {
    this.spinnerService.show();
    this.service.generateTxtFile(request).subscribe(
      (res) => {
        if (res.success && res.data) {
          const blob = new Base64ToBlob().convertToBlob(res.data, 'text/plain');
          const lang = localStorage.getItem('locale');
          const langFile =
            lang == 'en' ? 'batch-certificate' : 'certificado-lote';
          FileSaver.saveAs(blob, langFile);
        } else {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            'Erro ao baixar o certificado'
          );
        }

        this.spinnerService.hide();
      },
      (err) => {
        this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', err);
        this.spinnerService.hide();
      }
    );
  }

  generatePdfFile(request: BurderDownloadRequest) {
    this.spinnerService.show();
    this.service.generatePdfFile(request).subscribe(
      (res) => {
        if (res.success && res.data) {
          const blob = new Base64ToBlob().convertToBlob(
            res.data,
            'application/pdf'
          );
          const lang = localStorage.getItem('locale');
          const langFile =
            lang == 'en' ? 'batch-certificate' : 'certificado-lote';
          FileSaver.saveAs(blob, langFile);
        } else {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            'Erro ao baixar o certificado'
          );
        }

        this.spinnerService.hide();
      },
      (err) => {
        this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', err);
        this.spinnerService.hide();
      }
    );
  }
}
