
export const environment = {
  production: false,  
  api: 'https://hom.api.rastreabilidade.abrapa.coopersystem.com.br/api/v1',
  keycloakUrl: 'https://hom.autenticacao.abrapa.coopersystem.com.br/auth',
  //abr_api: 'https://hom.api.novoabr.abrapa.coopersystem.com.br',
  abr_api: 'https://api.abr.abrapa.com.br',
  abr_uba_api: 'https://abr-uba.abrapa.com.br',
  appUrl: 'https://hom.rastreabilidade.abrapa.coopersystem.com.br'
};

