import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
  selector: 'rastreabilidade-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  @Input() public requestId = null;
  histories: any[] = [];
  mySubscription: any;
  @Input() isRequester: boolean = false;

  historyForm = this.fb.group({
    requestId: [null, Validators.required],
    title: [null, [Validators.required]],
    description: [null, Validators.required],
  });

  constructor(
    private services: HttpService,
    private fb: FormBuilder,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.reloadHistory();
    this.historyForm.controls.requestId.setValue(this.requestId);
  }

  reloadHistory() {
    // buscar e carregar dados do histórico
    this.loadHistory(this.isRequester);
  }

  loadHistory(isRequester: boolean) {
    if (isRequester) {
      this.services
        .get(`Historic/historyForUser/${this.requestId}`)
        .subscribe((res) => {
          this.histories = res.data;
        });
    } else {
      this.services
        .get(`Historic/historic/${this.requestId}`)
        .subscribe((res) => {
          this.histories = res.data;
        });
    }
  }

  reset() {
    this.historyForm.reset();
  }

  submit() {
    let form = this.historyForm.value;
    this.services
      .post(`Historic/saveHistory/${this.requestId}`, form)
      .subscribe((res) => {
        this.loadHistory(this.isRequester);
        this.reset();
        this.toast.showToast(
          ToastTypeEnum.SUCCESS,
          'Sucesso',
          'Sucesso ao salvar mensagem.'
        );
      });
  }

  containsHyphenIssuingUser(issuingUser: string) {
    // Verifica se o email contém um hífen no nome antes do @
    const hasHyphen =
      issuingUser.includes('-') &&
      issuingUser.indexOf('-') < issuingUser.indexOf('@');
    return hasHyphen ? null : { hasHyphen: true };
  }
}
