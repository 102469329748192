<card>
  <card-header>
    <div class="container text-responsive" translate>
      header.accreditation_status_report
    </div>
  </card-header>
  <card-body>
    <div class="container">
      <form [formGroup]="formFilter">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="requester" translate> REPORTS.GENERIC.REQUESTER </label>
            <input
              type="text"
              class="form-control"
              id="requester"
              [ngbTypeahead]="searchRequester"
              [inputFormatter]="formatterRequester"
              [resultFormatter]="formatterRequester"
              (editable)="(false)"
              (selectItem)="setValueForm($event, 'requesterId')"
              #inputRequester
            />
          </div>
          <div class="form-group col-md-4">
            <label for="resquest__status" translate>
              REPORTS.GENERIC.ACCREDITATION_STATUS
            </label>
            <select
              formControlName="requestStatusId"
              class="form-control"
              id="resquest__status"
            >
              <option translate>generic.search.select</option>
              <option *ngFor="let opt of status" [value]="opt.key">
                {{ opt.value }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="requester__type" translate>
              REPORTS.GENERIC.REQUESTER_TYPE
            </label>
            <select
              formControlName="brazil"
              class="form-control"
              id="requester__type"
            >
              <option translate>generic.search.select</option>
              <option translate value="1">reports.requester.foreign</option>
              <option translate value="0">reports.requester.brazilians</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="search__period" translate>
              REPORTS.GENERIC.PERIOD
            </label>
            <div class="input-group">
              <input
                type="text"
                formControlName="initDateInput"
                placeholder="{{ 'GENERIC.DATE_INPUT_START' | translate }}"
                class="form-control"
                bsDatepicker
                [bsConfig]="bsConfig"
              />
            </div>
          </div>
          <div class="form-group col-md-4 d-flex align-items-end">
            <div class="input-group">
              <input
                type="text"
                formControlName="finishDateInput"
                placeholder="{{ 'GENERIC.DATE_INPUT_END' | translate }}"
                class="form-control"
                bsDatepicker
                [bsConfig]="bsConfig"
              />
            </div>
          </div>
        </div>
        <div class="form-row d-flex buttons-form-content aling_end">
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-outline-primary col-md-9"
              (click)="clearFilter($event, inputRequester)"
              translate
            >
              generic.buttons.clean
            </button>
          </div>
          &nbsp;
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-primary"
              (click)="refreshPage()"
              translate
            >
              generic.buttons.search
            </button>
          </div>
        </div>
      </form>

      <div class="search__result__labels">
        <label class="range__label">
          <label *ngIf="formFilter?.controls.startPeriod.value !== null"
            >{{ "generic.search.period" | translate }}:
            {{ formFilter?.controls.startPeriod.value | date : "shortDate" }}
          </label>
          <label *ngIf="formFilter?.controls.endPeriod.value !== null"
            >&nbsp;{{ "generic.search.to" | translate }}
            {{ formFilter?.controls.endPeriod.value | date : "shortDate" }}
          </label>
        </label>
        <label class="total__result">
          <div class="row col-md-12 d-flex justify-content-end">
            <p translate>generic.search.foreign_totalizer</p>
            {{ totalForeign }}
            <p translate>generic.search.brazilian_totalizers</p>
            {{ totalNational }}
          </div>
        </label>
      </div>
      <hr />
      <div class="table-responsive">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" translate>reports.requester.request_number</th>
              <th scope="col" translate>reports.requester.requester</th>
              <th scope="col" translate>reports.requester.foreign</th>
              <th scope="col" translate>reports.requester.brazilians</th>
              <th scope="col" translate>
                reports.requester.query_request_status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let request of requestStatusReports">
              <td>
                <a
                  [routerLink]="[
                    '/gerenciar-pedidos-de-acesso/editar',
                    request?.id
                  ]"
                  routerLinkActive="router-link-active"
                >
                  {{ request?.code }}
                </a>
              </td>
              <td>{{ request?.requesterName }}</td>
              <td>{{ request?.brazil ? "" : "x" }}</td>
              <td>{{ request?.brazil ? "x" : "" }}</td>
              <td>{{ request?.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-content flex-lg-row p-2">
        <p>
          <label translate>GENERIC.PAGINATION.LABEL.SHOWING</label>
          <label>&nbsp; {{ currentPageSize }}</label
          >&nbsp;
          <label translate>GENERIC.PAGINATION.LABEL.REGISTERS</label>
          <label>&nbsp; {{ collectionSize }}</label>
        </p>
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [maxSize]="width > 767 ? 5 : 3"
          [rotate]="true"
          [pageSize]="pageSize"
          (pageChange)="refreshPage()"
          class="d-flex d-lg-block justify-content-center"
        >
        </ngb-pagination>
        <div class="d-flex justify-content-center">
          <select
            class="custom-select"
            style="width: auto"
            [(ngModel)]="pageSize"
            (ngModelChange)="refreshPage()"
          >
            <option [ngValue]="10" translate>
              10
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="25" translate>
              25
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="50" translate>
              50
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="100" translate>
              100
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
          </select>
        </div>
      </div>
      <h6>
        <div class="col-md-12 download-content">
          <h6>
            <app-download
              [type]="'file-csv'"
              label="{{ 'generic.buttons.download_csv' | translate }}"
              [direction]="'row'"
              (onClick)="downloadArquivoCsv()"
            >
            </app-download>
          </h6>
        </div>
      </h6>
    </div>
  </card-body>
</card>
