<p class="p-black" translate>search_by_bale.burden_data</p>
<div class="form-row">
  <div class="col-md-1 search-by-bale-content-responsive">
    <label translate> search_by_bale.code </label>
  </div>
  <div class="col-md-5 search-by-bale-content-responsive">
    <label>{{ baleInformation?.code }}</label>
  </div>
  <div class="col-md-2 search-by-bale-content-responsive">
    <label translate> search_by_bale.crop_year </label>
  </div>
  <div class="col-md-4 search-by-bale-content-responsive">
    <label>{{ baleInformation?.cropYearDto?.description }}</label>
  </div>
</div>
