import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rastreabilidade-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  public googleMapsUrl = 'https://www.google.com/maps/search/?api=1&query=';

  @Input() public latitude;
  @Input() public longitude;

  width: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.width = window.innerWidth;
  }

  mapOptions: google.maps.MapOptions = {
    zoomControl: true,
    mapTypeControl: true,
    streetViewControl: false,
    fullscreenControl: true,
    disableDefaultUI: true,
    zoom: 16,
    mapTypeId: 'satellite',
  };

  marker: any = {
    position: {
      lat: null,
      lng: null,
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.width = window.innerWidth;
    this.mapOptions.center = new google.maps.LatLng({
      lat: parseFloat(this.latitude),
      lng: parseFloat(this.longitude),
    });
    this.marker.position.lat = parseFloat(this.latitude);
    this.marker.position.lng = parseFloat(this.longitude);
  }
}
