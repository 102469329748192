<hr />
<p class="p-black" translate>search_by_bale.origin_of_production</p>
<div class="row">
  <div class="col">
    <div class="my-google-map">
      <rastreabilidade-map
        [latitude]="productionOrigin?.addressHviDto.latitude"
        [longitude]="productionOrigin?.addressHviDto.longitude"
      >
      </rastreabilidade-map>
    </div>
  </div>

  <div class="col">
    <div class="row">
      <div class="col">
        <label translate> search_by_bale.producer_group </label>
        <p>{{ productionOrigin?.producerGroup }}</p>
      </div>
      <div class="col">
        <label translate> search_by_bale.productive_unit </label>
        <p>{{ productionOrigin?.productiveUnit }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label translate> search_by_bale.address </label>
        <p>
          {{ productionOrigin?.addressHviDto?.description }},
          {{ productionOrigin?.addressHviDto.city }},
          {{ productionOrigin?.addressHviDto.district }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <a
          class="btn btn-outline-primary button-google-maps-responsive"
          target="_blank"
          href="{{ googleMapsUrl }}"
          translate
        >
          search_by_bale.localization
        </a>
      </div>
    </div>
  </div>
</div>
