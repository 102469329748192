import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BurdenService } from 'src/app/core/services/burden.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import {
  BurdenBatchGrouped,
  BurdenBatchProducer,
  BurdenRequestViewModel,
} from 'src/app/shared/models/burden/burden-request-view-model';
import {
  BurderDownloadRequest,
  GenerateArchiveBurdenRequestInvalidViewModel,
  TradingInOriginProducer,
} from 'src/app/shared/models/burden/burder-download-request.model';
import * as FileSaver from 'file-saver';
import { Base64ToBlob } from 'src/app/shared/utils/base64ToBlob';
import { TranslateService } from '@ngx-translate/core';
import { ModalConfig } from '../../../../shared/components/modal/modal.config';
import { ModalComponent } from '../../../../shared/components/modal/modal.component';

interface BurdenSelected {
  id: string;
  isTrading: boolean;
  checked: boolean;
  disabled: boolean;
}

@Component({
  selector: 'rastreabilidade-view-request',
  templateUrl: './view-request.component.html',
  styleUrls: ['./view-request.component.scss'],
})
export class ViewRequestComponent implements OnInit {
  @ViewChild('modalDetalhesTrading')
  private modalDetalhesTrading: ModalComponent;
  id: string;
  burden: BurdenRequestViewModel = null;
  burdensSelected: BurdenSelected[] = [];
  burdensTradingSelected: BurdenBatchGrouped = null;
  disabledCheckAll: boolean = true;
  collapsed: boolean[] = [];

  constructor(
    private burdenService: BurdenService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private toastService: ToastService
  ) {}

  public modalConfigDetalhesTrading: ModalConfig = {
    modalTitle: '',
    hideSaveButton: true,
    hideDenyButton: true,
    closeButtonLabel: 'Ok',
    options: {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      keyboard: false,
    },
    onClose: async () => {
      this.burdensTradingSelected = null;
      return true;
    },
  };

  ngOnInit(): void {
    this.spinnerService.show();
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.translate
      .get('burden_requests.view_request.trading_detail')
      .subscribe((msg) => (this.modalConfigDetalhesTrading.modalTitle = msg));

    this.translate
      .get('generic.buttons.cancel')
      .subscribe(
        (msg) => (this.modalConfigDetalhesTrading.closeButtonLabel = msg)
      );

    this.burdenService.getDetailBurdenRequest(this.id).subscribe((res) => {
      if (res.success) {
        this.burden = res.data;
        this.spinnerService.hide();
        if (this.burden.noSbrHvi != undefined) {
          var msgm = '';
          var cabecalho = '';
          this.translate
            .get('burden_requests.burden_non_sbrhvi_msgm')
            .subscribe((msg) => (msgm = msg));
          this.translate
            .get('burden_requests.burden_non_sbrhvi_header')
            .subscribe((msg) => (cabecalho = msg));

          this.toastService.showToast(ToastTypeEnum.ALERT, cabecalho, msgm);
        }
      }
    });
  }

  onSelectAll($event, burden: BurdenRequestViewModel) {
    burden?.burdens?.forEach((element) => {
      if (element?.trading && element?.trading?.status?.key !== 1) {
        this.onCheckboxTradingChange($event, element);
      } else {
        element?.burdens?.forEach((burden) => {
          let id = '';
          if (element?.trading)
            id = `${element?.trading?.tradeId}/${burden?.originProducerId}`;
          else id = burden?.originProducerId;

          this.onCheckboxBurderIntenalChange($event, id);
        });
      }
    });
  }

  onCheckboxTradingChange($event, burden: BurdenBatchGrouped) {
    if ($event.target.checked) {
      let burdensSelected: BurdenSelected[] = [];
      burdensSelected.push({
        id: burden.trading.tradeId,
        isTrading: true,
        checked: true,
        disabled: false,
      });

      burdensSelected = burdensSelected.concat(
        burden.burdens.map((x) => {
          return {
            id: `${burden.trading.tradeId}${x.originProducerId}`,
            isTrading: false,
            checked: true,
            disabled: true,
          };
        })
      );

      this.burdensSelected = burdensSelected;
    } else {
      let burdensSelected: BurdenSelected[] = [];
      burdensSelected.push({
        id: burden.trading.tradeId,
        isTrading: true,
        checked: false,
        disabled: false,
      });

      burdensSelected = burdensSelected.concat(
        burden.burdens.map((x) => {
          return {
            id: `${burden.trading.tradeId}${x.originProducerId}`,
            isTrading: false,
            checked: false,
            disabled: false,
          };
        })
      );

      this.burdensSelected = burdensSelected;
    }
  }

  onCheckboxBurderIntenalChange($event, id: string) {
    if ($event.target.checked) {
      let burdensSelected: BurdenSelected[] = this.burdensSelected;
      const burdenSelected = this.burdensSelected.find((x) => x.id === id);

      if (burdenSelected) {
        burdensSelected.forEach((x) => {
          if (x.id === burdenSelected.id) {
            x.checked = true;
          }
        });
      } else {
        burdensSelected.push({
          id,
          isTrading: false,
          checked: true,
          disabled: false,
        });
      }

      this.burdensSelected = burdensSelected;
    } else {
      let burdensSelected: BurdenSelected[] = this.burdensSelected;
      const burdenSelected = this.burdensSelected.find((x) => x.id === id);

      if (burdenSelected) {
        burdensSelected.forEach((x) => {
          if (x.id === burdenSelected.id) {
            x.checked = false;
          }
        });
      } else {
        burdensSelected.push({
          id,
          isTrading: false,
          checked: false,
          disabled: false,
        });
      }

      this.burdensSelected = burdensSelected;
    }
  }

  getBurdensSelected(id: string) {
    let newId = id?.toString().replace('/', '');
    const burderSelected = this.burdensSelected.find((x) => x.id === newId);
    if (burderSelected) {
      return burderSelected.checked;
    }
    return false;
  }

  getBurdensDisabled(id: string) {
    let newId = id?.toString().replace('/', '');
    const burderSelected = this.burdensSelected.find((x) => x.id === newId);
    if (burderSelected) {
      return burderSelected.disabled;
    }
    return false;
  }

  checkIfDisabled() {
    if (this.burdensSelected.length > 0) {
      for (const item of this.burdensSelected) {
        if (item.checked) {
          return false;
        }
      }
    }
    return true;
  }

  downloadOutrosArquivo(idStatus: number) {
    const request: GenerateArchiveBurdenRequestInvalidViewModel = {
      burdenRequestId: this.id,
      status: idStatus,
    };

    this.generateTxtCertificateInvalidInBatch(request);
  }

  moountRequestArquivo(
    id: string,
    isTrading: boolean,
    tradeId: string
  ): BurderDownloadRequest {
    if (isTrading && tradeId != null && tradeId != '') {
      return {
        burdenRequestId: this.id,
        originProducers: [],
        tradingInOriginProducer: [
          {
            tradeId: tradeId,
            originProducers: [id],
          },
        ],
      };
    }

    if (isTrading) {
      return {
        burdenRequestId: this.id,
        tradingInOriginProducer: [
          {
            tradeId: id,
            originProducers: [],
          },
        ],
      };
    } else {
      return {
        burdenRequestId: this.id,
        tradingInOriginProducer: null,
        originProducers: [id],
      };
    }
  }

  downloadArquivo(id: string, isTrading: boolean, tradeId: string = null) {
    const request = this.moountRequestArquivo(id, isTrading, tradeId);

    this.generateTxtCertificateInBatch(request);
  }

  downloadPDF(id: string, isTrading: boolean, tradeId: string = null) {
    const request = this.moountRequestArquivo(id, isTrading, tradeId);

    this.generatePdfCertificateInBatch(request);
  }

  mountRequestArquivoLotes(): BurderDownloadRequest {
    const listTrading = this.burdensSelected.filter(
      (x) => x.isTrading && x.checked
    );
    const listOriginProducers = this.burdensSelected.filter(
      (x) => !x.isTrading && x.checked
    );
    let tradingInOriginProducer: TradingInOriginProducer[] = [];

    if (listTrading !== null && listTrading.length > 0) {
      for (const trading of listTrading) {
        const tradingSelected = this.burden.burdens.find(
          (x) => x.trading?.tradeId === trading.id
        );
        tradingInOriginProducer.push({
          tradeId: tradingSelected.trading.tradeId,
          originProducers: tradingSelected.burdens.map(
            (x) => x.originProducerId
          ),
        });
      }
    }

    let originProducers: BurdenBatchProducer[] = [];
    if (listOriginProducers.length > 0) {
      for (const originProducer of listOriginProducers) {
        if (originProducer?.id?.toString().indexOf('/') >= 0) {
          let split = originProducer.id.toString().split('/');
          tradingInOriginProducer.push({
            tradeId: split[0],
            originProducers: [split[1]],
          });
        }
        const burdens = this.burden.burdens
          .filter((x) => !x.trading)
          .map((x) => x.burdens)[0];
        if (burdens) {
          const burdenSelected = burdens.find(
            (x) => x.originProducerId === originProducer.id
          );
          if (burdenSelected) {
            originProducers.push(burdenSelected);
          }
        }
      }
    }

    return {
      burdenRequestId: this.id,
      tradingInOriginProducer,
      originProducers: originProducers.map((x) => x.originProducerId),
    };
  }

  downloadArquivoLotes() {
    const request = this.mountRequestArquivoLotes();

    this.generateTxtCertificateInBatch(request);
  }

  downloadLotesPDF() {
    const request = this.mountRequestArquivoLotes();

    this.generatePdfCertificateInBatch(request);
  }

  generatePdfCertificateInBatch(request: BurderDownloadRequest) {
    this.spinnerService.show();
    this.burdenService.generatePdfCertificateInBatch(request).subscribe(
      (res) => {
        if (res.success && res.data) {
          const blob = new Base64ToBlob().convertToBlob(
            res.data,
            'application/pdf'
          );
          const lang = localStorage.getItem('locale');
          const langFile =
            lang == 'en' ? 'batch-certificate' : 'certificado-lote';
          FileSaver.saveAs(blob, langFile);
        } else {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            'Erro ao baixar o certificado'
          );
        }

        this.spinnerService.hide();
      },
      (err) => {
        this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', err);
        this.spinnerService.hide();
      }
    );
  }

  generateTxtCertificateInBatch(request: BurderDownloadRequest) {
    this.spinnerService.show();
    this.burdenService.generateTxtCertificateInBatch(request).subscribe(
      (res) => {
        if (res.success && res.data) {
          const blob = new Base64ToBlob().convertToBlob(res.data, 'text/plain');
          const lang = localStorage.getItem('locale');
          const langFile =
            lang == 'en' ? 'batch-certificate' : 'certificado-lote';
          FileSaver.saveAs(blob, langFile);
        } else {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            'Erro ao baixar o certificado'
          );
        }

        this.spinnerService.hide();
      },
      (err) => {
        this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', err);
        this.spinnerService.hide();
      }
    );
  }

  generateTxtCertificateInvalidInBatch(
    request: GenerateArchiveBurdenRequestInvalidViewModel
  ) {
    this.spinnerService.show();
    this.burdenService.generateTxtCertificateInvalidInBatch(request).subscribe(
      (res) => {
        if (res.success && res.data) {
          const blob = new Base64ToBlob().convertToBlob(res.data, 'text/plain');
          FileSaver.saveAs(blob, 'arquivo');
        } else {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            'Erro ao baixar o certificado'
          );
        }

        this.spinnerService.hide();
      },
      (err) => {
        this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', err);
        this.spinnerService.hide();
      }
    );
  }

  getColorClass(idStatus: number): string {
    switch (idStatus) {
      case 0:
        return 'circle-green';
      case 1:
        return 'circle-orange';
      case 2:
        return 'circle-green';
      case 3:
        return 'circle-red';
      default:
        break;
    }
  }

  getColorClassGeneralStatus(status: string): string {
    switch (status?.toLowerCase()) {
      case 'em andamento' || 'in progress':
        return 'circle-orange';
      case 'concluído' || 'done':
        return 'circle-green';
      case '':
        return 'circle-red';
      default:
        break;
    }
  }

  showDownload(status): boolean {
    if (status?.key === 2) this.disabledCheckAll = false;

    return status?.key === 2;
  }

  isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  showTrading(burden: BurdenBatchGrouped) {
    this.burdensTradingSelected = burden;
    this.modalDetalhesTrading.open();
  }
  toggleCollapse(index: number, event: any) {
    event.preventDefault();
    this.collapsed[index] = !this.collapsed[index];
  }
}
