import { Component, HostListener, OnInit } from '@angular/core';
import { BurdenService } from 'src/app/core/services/burden.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { BurdenRequestResponse } from 'src/app/shared/models/burden/burden-request-response';

@Component({
  selector: 'rastreabilidade-home-batch-search',
  templateUrl: './home-batch-search.component.html',
  styleUrls: ['./home-batch-search.component.scss'],
})
export class HomeBatchSearchComponent implements OnInit {
  burdenRequests: BurdenRequestResponse[] = [];
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  currentPageSize = 10;
  width: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.width = window.innerWidth;
  }

  constructor(
    private burdenService: BurdenService,
    private spinnerService: SpinnerService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getRequests();
    this.width = window.innerWidth;
  }

  getRequests() {
    this.spinnerService.show();
    this.burdenService
      .getBurdenRequest(this.page, this.pageSize)
      .subscribe(
        (res) => {
          this.burdenRequests = res.data.data;
          this.currentPageSize = res.data.data.length;
          this.collectionSize = res.data.totalItems;
        },
        (err) => {
          this.toastService.showToast(ToastTypeEnum.DANGER, 'Error', err);
        }
      )
      .add(() => {
        this.spinnerService.hide();
      });
  }

  refreshPage(): void {
    this.getRequests();
  }

  getColorClass(idStatus: number): string {
    switch (idStatus) {
      case 1:
        return 'circle-orange';
      case 2:
        return 'circle-green';
      case 3:
        return 'circle-red';
      case 5:
        return 'circle-green';
      default:
        break;
    }
  }
}
