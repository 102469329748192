<div>
  <a
    (click)="generateCertificate()"
    class="text-decoration-none anchor_big"
    translate
  >
    <fa-icon [icon]="['fas', 'file-pdf']"></fa-icon>
    search_by_bale.generate_traceability_certificate
  </a>
</div>
