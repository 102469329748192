import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EventsService } from 'src/app/core/services/events.service';
import { HttpService } from 'src/app/core/services/http.service';
import { ReportsService } from 'src/app/core/services/reports.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
  selector: 'rastreabilidade-home-manage-access-request',
  templateUrl: './home-manage-access-request.component.html',
  styleUrls: ['./home-manage-access-request.component.scss'],
})
export class HomeManageAccessRequestComponent implements OnInit {
  accessRequests: any[] = [];
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  currentPageSize = 10;
  isLoading: boolean = false;
  bsConfig?: Partial<BsDatepickerConfig>;
  requestersList: any;

  width: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.width = window.innerWidth;
  }

  filterAccessRequest = this.fb.group({
    requester_id: [null],
    cpf_passport: [''],
    status: [null],
    country: [''],
    corporative_email: [''],
    company_name: [''],
    start_period: [null],
    end_period: [null],
    initDateInput: [''],
    finishDateInput: [''],
  });

  constructor(
    public services: HttpService,
    private serviceReport: ReportsService,
    public fb: FormBuilder,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private dateLocale: BsLocaleService,
    private config: NgbTypeaheadConfig,
    private eventService: EventsService
  ) {
    this.dateLocale.use(localStorage.getItem('locale'));
    config.showHint = false;
  }

  ngOnInit() {
    this.width = window.innerWidth;
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });

    this.filterAccessRequest
      .get('initDateInput')
      .valueChanges.subscribe((ret: Date) => {
        let date = `${ret.getFullYear()}-${('0' + (ret.getMonth() + 1)).slice(
          -2
        )}-${('0' + ret.getDate()).slice(-2)}`;
        this.filterAccessRequest.controls.start_period.setValue(date);
      });

    this.filterAccessRequest
      .get('finishDateInput')
      .valueChanges.subscribe((ret) => {
        let date = `${ret.getFullYear()}-${('0' + (ret.getMonth() + 1)).slice(
          -2
        )}-${('0' + ret.getDate()).slice(-2)}`;
        this.filterAccessRequest.controls.end_period.setValue(date);
      });

    this.refreshPage();
  }

  getRequests() {
    this.services
      .getWithParams(`Access/requests`, {
        ...this._filterRequest(),
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      .subscribe(
        (res) => {
          this.accessRequests = res.data.data;
          this.eventService._accessRequests.emit(this.accessRequests);
          this.currentPageSize = res.data.data.length;
          this.collectionSize = res.data.totalItems;
        },
        (err) => {
          this.toastService.showToast(ToastTypeEnum.DANGER, 'Error', err);
        }
      )
      .add(() => {
        this.hideSpinner();
      });
  }

  private _filterRequest() {
    if (this.filterAccessRequest.value.status === null) {
      return {
        ...this.filterAccessRequest.value,
        status: '',
      };
    }
    return this.filterAccessRequest.value;
  }

  setValueForm(event: any, selectedControl: string, input: any = null) {
    switch (selectedControl) {
      case 'requester_id':
        this.filterAccessRequest.controls.requester_id.setValue(event.item.key);
        break;
      default:
        event.preventDefault();
        input.value = '';
        this.filterAccessRequest.controls.requester_id.setValue(null);
    }
  }

  formatterRequester(x: { value: string }) {
    return x.value;
  }

  searchRequester = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      map((term) => (term.length < 2 ? [] : this.getRequester(term)))
    );

  getRequester(param: string) {
    this.serviceReport.getRequester(param).subscribe((res) => {
      this.requestersList = res.data;
    });

    return this.requestersList;
  }

  refreshPage(): void {
    this.showSpinner();
    this.getRequests();
  }

  showSpinner() {
    this.isLoading = true;
    this.spinnerService.show();
  }

  hideSpinner() {
    this.isLoading = false;
    this.spinnerService.hide();
  }

  reset(event, input) {
    this.setValueForm(event, '', input);

    this.filterAccessRequest = this.fb.group({
      requester_id: [''],
      cpf_passport: [''],
      status: [null],
      country: [''],
      corporative_email: [''],
      company_name: [''],
      initDateInput: [''],
      finishDateInput: [''],
      start_period: [null],
      end_period: [null],
    });

    this.ngOnInit();
  }
}
