<ng-container
  *ngIf="width > 767; then isWebView; else isMobileView"
></ng-container>
<ng-template #isWebView>
  <div class="header">
    <nav class="navbar navbar-light bg-primary navbar-mobile-container">
      <div class="container justify-content-between navbar-mobile">
        <a class="navbar-brand" [routerLink]="'home'">
          <img
            src="../assets/images/logo_abrapa_branco.svg"
            alt="ABRAPA-ABR"
            class="logo-abrapa"
          />
          <!-- <img *ngIf="languageSelected == 'pt' " src="../assets/images/logo_sai_branco.svg" alt="SAI" class="logo-sai" />
          <img *ngIf="languageSelected == 'en' " src="../assets/images/logo_sai_branco_en.svg" alt="SAI" class="logo-sai" /> -->
        </a>
        <div class="authorized navbar-mobile" *ngIf="authorized">
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="language"
            >
              <fa-icon [icon]="['fas', 'globe']"></fa-icon> {{ getLanguage() }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="language">
              <li>
                <a class="dropdown-item" (click)="changeLang('pt')" translate
                  >monitoring.form.labels.portuguese</a
                >
              </li>
              <li>
                <a class="dropdown-item" (click)="changeLang('en')" translate
                  >monitoring.form.labels.english</a
                >
              </li>
            </ul>
          </div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="user"
            >
              <fa-icon [icon]="['fas', 'user']"></fa-icon>
              {{ "header.greetings" | translate }}, {{ service.loginUsuario }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="user">
              <li
                *ngIf="isSolicitante"
                [routerLink]="['editar-solicitante', accessRequestId]"
              >
                <a class="dropdown-item" translate>header.perfil</a>
              </li>
              <li (click)="service.logout()">
                <a class="dropdown-item" translate>header.logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-light secondary">
      <div class="container">
        <div class="navbar-collapse" id="navbarText">
          <ul class="nav mr-auto nav__menu navbar-nav">
            <ng-container *ngIf="isAbrapa">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="administrative"
                  translate
                  >header.administrative</a
                >
                <ul class="dropdown-menu" aria-labelledby="administrative">
                  <li>
                    <a
                      class="dropdown-item"
                      [routerLink]="['configuracao-certificado']"
                      translate
                      >header.certificate_configuration</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      [routerLink]="['configuracao-monitoramento']"
                      translate
                      >header.monitoring_configuration</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a
                  [routerLink]="['gerenciar-pedidos-de-acesso']"
                  class="nav-link"
                  translate
                  >header.applicant_accreditation</a
                >
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  translate
                  >header.reports</a
                >
                <ul
                  class="dropdown-menu dropdown-menu-md-end"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li class="dropdown-submenu dropleft">
                    <a class="dropdown-item dropdown-toggle" translate>
                      header.producer_trading
                    </a>
                    <ul class="dropdown-menu" style="right: 190px; left: auto">
                      <li>
                        <a
                          class="dropdown-item drop sub__menu dropleft"
                          [routerLink]="['relatorios/produtor-solicitante']"
                          translate
                          >header.producer_applicant</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item drop sub__menu dropleft"
                          [routerLink]="['relatorios/trading-solicitante']"
                          translate
                          >header.trading_applicant</a
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown-submenu dropleft">
                    <a class="dropdown-item dropdown-toggle" translate
                      >header.accreditation</a
                    >
                    <ul class="dropdown-menu" style="right: 190px; left: auto">
                      <li>
                        <a
                          class="dropdown-item drop sub__menu"
                          [routerLink]="['relatorios/status-credenciamento']"
                          translate
                          >header.request_status</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item drop sub__menu"
                          [routerLink]="['relatorios/tempo-medio']"
                          translate
                          >header.average_time</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ng-container>
            <ng-container *ngIf="isSolicitante">
              <li class="nav-item dropdown menu__itens">
                <a
                  [routerLink]="['consulta-fardo-a-fardo']"
                  class="nav-link main__item"
                  translate
                  >header.search_by_bale</a
                >
              </li>
              <li class="nav-item dropdown menu__itens">
                <a
                  [routerLink]="['consulta-por-lote']"
                  class="nav-link main__item"
                  translate
                  >header.batch_search</a
                >
              </li>
              <li class="nav-item dropdown menu__itens">
                <a
                  [routerLink]="['relatorios/solicitante']"
                  class="nav-link main__item"
                  translate
                  >header.reports</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</ng-template>
<ng-template #isMobileView>
  <div class="header">
    <nav class="navbar navbar-light bg-primary">
      <div class="container navbar-mobile">
        <a class="navbar-brand" [routerLink]="'home'">
          <img
            src="../assets/images/logo_abrapa_branco.svg"
            alt="ABRAPA-ABR"
            class="logo-abrapa"
          />
          <!-- <img *ngIf="languageSelected == 'pt' " src="../assets/images/logo_sai_branco.svg" alt="SAI" class="logo-sai" />
          <img *ngIf="languageSelected == 'en' " src="../assets/images/logo_sai_branco_en.svg" alt="SAI" class="logo-sai" /> -->
        </a>
        <div
          *ngIf="authorized"
          class="content-navbar-primary accordion"
          id="accordion"
        >
          <div>
            <button
              id="language"
              class="btn btn-primary"
              type="button"
              data-toggle="collapse"
              data-target="#languageCollapse"
              aria-expanded="false"
              aria-controls="languageCollapse"
            >
              <fa-icon [icon]="['fas', 'globe']"></fa-icon>
              {{ getLanguage() }}
              <fa-icon
                class="chevron-button"
                [icon]="['fas', 'chevron-down']"
              ></fa-icon>
            </button>
            <ul
              class="collapse menu-collapse"
              id="languageCollapse"
              aria-labelledby="languageCollapse"
              data-parent="#accordion"
            >
              <li (click)="changeLang('pt')">
                <a class="item-collapse" translate>
                  monitoring.form.labels.portuguese
                </a>
              </li>
              <li (click)="changeLang('en')">
                <a class="item-collapse" translate>
                  monitoring.form.labels.english
                </a>
              </li>
            </ul>
          </div>
          <div>
            <button
              id="user"
              class="btn btn-primary"
              type="button"
              data-toggle="collapse"
              data-target="#userCollapse"
              aria-expanded="false"
              aria-controls="userCollapse"
            >
              <fa-icon [icon]="['fas', 'user']"></fa-icon>
              {{ "header.greetings" | translate }}, {{ service.loginUsuario }}
              <fa-icon
                class="chevron-button"
                [icon]="['fas', 'chevron-down']"
              ></fa-icon>
            </button>
            <ul
              class="collapse menu-collapse"
              id="userCollapse"
              aria-labelledby="userCollapse"
              data-parent="#accordion"
            >
              <li
                *ngIf="isSolicitante"
                [routerLink]="['editar-solicitante', accessRequestId]"
              >
                <a class="item-collapse" translate>header.perfil</a>
              </li>
              <li (click)="service.logout()">
                <a class="item-collapse" translate>header.logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-light secondary">
      <div class="container navbar-mobile">
        <div class="content-navbar-secondary accordion" id="settingsAccordion">
          <ng-container *ngIf="isAbrapa">
            <div class="items-content">
              <button
                id="administrative"
                class="btn btn-secondary"
                type="button"
                data-toggle="collapse"
                data-target="#administrativeCollapse"
                aria-expanded="false"
                aria-controls="administrativeCollapse"
                translate
              >
                header.administrative
                <fa-icon
                  class="chevron-button"
                  [icon]="['fas', 'chevron-down']"
                ></fa-icon>
              </button>
              <ul
                class="collapse menu-collapse-administrative"
                id="administrativeCollapse"
                aria-labelledby="administrativeCollapse"
                data-parent="#settingsAccordion"
              >
                <li>
                  <a
                    class="item-collapse"
                    [routerLink]="['configuracao-certificado']"
                    translate
                    >header.certificate_configuration</a
                  >
                </li>
                <li>
                  <a
                    class="item-collapse"
                    [routerLink]="['configuracao-monitoramento']"
                    translate
                    >header.monitoring_configuration</a
                  >
                </li>
              </ul>
            </div>
            <div class="items-content">
              <a
                id="administrative"
                class="btn btn-secondary"
                [routerLink]="['gerenciar-pedidos-de-acesso']"
                translate
              >
                header.applicant_accreditation
              </a>
            </div>
            <div class="items-content accordion" id="settingsAccordion">
              <button
                id="administrative"
                class="btn btn-secondary"
                type="button"
                data-toggle="collapse"
                data-target="#reportsCollapse"
                aria-expanded="false"
                aria-controls="reportsCollapse"
                translate
              >
                header.producer_trading
                <fa-icon
                  class="chevron-button"
                  [icon]="['fas', 'chevron-down']"
                ></fa-icon>
              </button>
              <div
                id="reportsCollapse"
                aria-labelledby="reportsCollapse"
                class="collapse submenu-collapse accordion"
                data-parent="#settingsAccordion"
              >
                <div class="submenu-collapse-item">
                  <button
                    class="btn submenu-button"
                    type="button"
                    data-toggle="collapse"
                    data-target="#produtorTradingCollapse"
                    aria-expanded="false"
                    aria-controls="produtorTradingCollapse"
                    translate
                  >
                    header.producer_trading
                    <fa-icon
                      class="chevron-button"
                      [icon]="['fas', 'chevron-down']"
                    ></fa-icon>
                  </button>
                  <div
                    id="produtorTradingCollapse"
                    class="collapse submenu-collapse-content"
                    aria-labelledby="produtorTradingCollapse"
                    data-parent="#reportsCollapse"
                  >
                    <div class="submenu-collapse-subitem">
                      <a
                        class="submenu-item-link"
                        [routerLink]="['relatorios/produtor-solicitante']"
                        translate
                      >
                        header.producer_applicant
                      </a>
                      <a
                        class="submenu-item-link"
                        [routerLink]="['relatorios/trading-solicitante']"
                        translate
                      >
                        header.trading_applicant
                      </a>
                    </div>
                  </div>
                </div>
                <div class="submenu-collapse-item">
                  <button
                    class="btn submenu-button"
                    type="button"
                    data-toggle="collapse"
                    data-target="#credentialCollapse"
                    aria-expanded="false"
                    aria-controls="credentialCollapse"
                    translate
                  >
                    header.accreditation
                    <fa-icon
                      class="chevron-button"
                      [icon]="['fas', 'chevron-down']"
                    ></fa-icon>
                  </button>
                  <div
                    id="credentialCollapse"
                    class="collapse submenu-collapse-content"
                    aria-labelledby="credentialCollapse"
                    data-parent="#reportsCollapse"
                  >
                    <div class="submenu-collapse-subitem">
                      <a
                        class="submenu-item-link"
                        [routerLink]="['relatorios/status-credenciamento']"
                        translate
                      >
                        header.request_status
                      </a>
                      <a
                        class="submenu-item-link"
                        [routerLink]="['relatorios/tempo-medio']"
                        translate
                      >
                        header.average_time
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isSolicitante">
            <div class="items-content">
              <a
                id="administrative"
                class="btn btn-secondary"
                [routerLink]="['consulta-fardo-a-fardo']"
                translate
              >
                header.search_by_bale
              </a>
            </div>
            <div class="items-content">
              <a
                id="administrative"
                class="btn btn-secondary"
                [routerLink]="['consulta-por-lote']"
                translate
              >
                header.batch_search
              </a>
            </div>
            <div class="items-content">
              <a
                id="administrative"
                class="btn btn-secondary"
                [routerLink]="['relatorios/solicitante']"
                translate
              >
                header.reports
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </nav>
  </div>
</ng-template>
