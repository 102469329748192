<p class="p-black" translate>search_by_bale.certifications</p>
<div class="row">
  <div class="col-md-6 search-by-bale-content-responsive">
    <label translate> search_by_bale.abr_certification </label>
    <ng-template
      [ngIf]="socialEnvironmentalCertification?.abrCertification"
      [ngIfElse]="noCertification"
    >
      <a (click)="gerarCertificado()" target="_blank" class="link">
        <p>{{ socialEnvironmentalCertification?.abrCertification }}</p>
      </a>
    </ng-template>
  </div>

  <div class="col-md-6 search-by-bale-content-responsive">
    <label translate> search_by_bale.bci_licensing </label>
    <ng-template
      [ngIf]="socialEnvironmentalCertification?.bciLicensing"
      [ngIfElse]="noCertification"
    >
      <a (click)="downloadCertificadoBci()" target="_blank" class="link">
        <p>{{ socialEnvironmentalCertification?.bciLicensing }}</p>
      </a>
    </ng-template>
  </div>
</div>
<div class="row">
  <div class="col-md-6 search-by-bale-content-responsive">
    <label translate> search_by_bale.abr_uba_certification </label>
    <ng-template
      [ngIf]="
        socialEnvironmentalCertification?.abrUbaCertification &&
        socialEnvironmentalCertification?.abrUbaCertification
      "
      [ngIfElse]="noCertification"
    >
      <a
        (click)="gerarCertificacaoAbrUba()"
        target="_blank"
        [class]="
          socialEnvironmentalCertification?.abrUbaCertification ? 'link' : ''
        "
      >
        <p>
          {{ socialEnvironmentalCertification?.abrUbaCertification }}
        </p>
      </a>
    </ng-template>
  </div>
  <div class="col-md-6 search-by-bale-content-responsive">
    <label translate> search_by_bale.volunteer_self_control </label>
    <ng-template
      [ngIf]="
        mapaUrl && identificacaoMapa != '' && statusAnalysis != 'cancelado'
      "
      [ngIfElse]="noCertification"
    >
      <a [href]="mapaUrl" target="_blank" class="link">
        <p>
          {{ identificacaoMapa }}
        </p>
      </a>
    </ng-template>
  </div>

  <ng-template #certCancelado>
    <span style="font-size: 14px; font-weight: 100">{{
      identificacaoMapa
    }}</span>
    <span style="font-size: 14px; font-weight: 100" translate>
      search_by_bale.canceled_on
    </span>
    <span style="font-size: 14px; font-weight: 100">{{
      canceledDate | date : "MM/dd/yyyy"
    }}</span>
  </ng-template>

  <ng-template #noCertification>
    <p class="d-flex d-flex ml-5 search-by-bale-content-responsive">-</p>
  </ng-template>
</div>
