<card>
  <card-header>
    <div translate>monitoring.form.title_edit</div>
  </card-header>
  <card-body>
    <form class="form-inline" [formGroup]="configForm">
      <div class="row pl-3 pr-3 pb-3 pl-sm-0 pr-sm-0 content-responsive">
        <div class="col-md-6 pt-4">
          <div class="col-12 mb-4">
            <h6 class="text-responsive" translate>
              monitoring.form.sequency_title
            </h6>
          </div>
          <div class="form-group">
            <span class="col-md-auto pr-2"
              ><span translate>monitoring.form.labels.frequancy_limit</span
              >:</span
            >
            <input
              class="form-control col-md-3"
              type="number"
              name="maximumQuantity"
              formControlName="maximumQuantity"
              id="limit_approved"
              placeholder="0"
            />
            <div
              class="text-danger col-12 mt-1"
              *ngIf="fSubmited && f.maximumQuantity.errors?.required"
            >
              {{ "generic.validations.required" | translate }}
            </div>
          </div>
          <div class="form-group mt-4">
            <p class="col-12 content-center-responsive">
              <span class="pr-2"
                ><span translate
                  >monitoring.form.labels.verify_frequency_type</span
                >:</span
              >
              <span class="font-weight-lighter"
                >(<span translate
                  >monitoring.form.labels.verify_frequency_type_complement</span
                >)</span
              >
            </p>
            <div class="col-md-2">
              <div
                class="form-check first mt-2"
                *ngFor="let op of unitMeasuresOptions"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="unitMeasureId"
                  formControlName="unitMeasureId"
                  [id]="op.id"
                  [value]="op.id"
                />
                <label class="form-check-label" [for]="op.id">{{
                  op.unitMeasureDescription
                }}</label>
              </div>
            </div>
            <div class="col-md-3">
              <input
                class="form-control col-md-12 mt-1 number__input"
                type="number"
                min="0"
                name="frequencyCheck"
                formControlName="frequencyCheck"
                placeholder="0"
              />
            </div>
            <div
              class="text-danger col-12 mt-1"
              *ngIf="
                fSubmited &&
                (f.unitMeasureId.errors?.required ||
                  f.frequencyCheck.errors?.required)
              "
            >
              {{ "generic.validations.required" | translate }}
            </div>
          </div>
        </div>
        <div class="middle__line"></div>
        <div class="col-md-6 pt-4">
          <div class="col-12 mb-4">
            <h6 translate>monitoring.form.action_title</h6>
          </div>

          <div class="form-group mt-2" *ngFor="let op of actionsOptions">
            <div class="form-check others">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="radio"
                  name="actionsItemsId"
                  formControlName="actionsItemsId"
                  [value]="op.id"
                />
                <span>{{ op.actionDescription }}</span>
              </label>
            </div>
          </div>
          <div
            class="text-danger col-12 mt-1"
            *ngIf="fSubmited && f.actionsItemsId.errors?.required"
          >
            {{ "generic.validations.required" | translate }}
          </div>
          <div class="form-group mt-4">
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="notifyByEmail"
                  formControlName="notifyByEmail"
                  (change)="notifyByEmailChangeHandler($event)"
                />
                <span translate>monitoring.form.labels.notify_via_mail</span>
              </label>
            </div>
          </div>
          <div class="form-group mt-2" *ngIf="f.notifyByEmail.value">
            <label for="" translate
              >monitoring.form.labels.notification_message_pt</label
            >
            <textarea
              rows="5"
              name="notificationMessagePt"
              formControlName="notificationMessagePt"
              class="form-control col-md-12"
            ></textarea>
            <div
              class="text-danger col-12 mt-1"
              *ngIf="fSubmited && f.notificationMessagePt.errors?.required"
            >
              {{ "generic.validations.required" | translate }}
            </div>
            <div
              class="text-danger col-12 mt-1"
              *ngIf="fSubmited && f.notificationMessagePt.errors?.minlength"
            >
              {{
                "generic.validations.min_length"
                  | translate
                    : {
                        attr: trans?.notificationMessagePt,
                        min: f.notificationMessagePt.errors?.minlength
                          ?.requiredLength
                      }
              }}
            </div>
          </div>
          <div class="form-group mt-2" *ngIf="f.notifyByEmail.value">
            <label for="" translate
              >monitoring.form.labels.notification_message_en</label
            >
            <textarea
              rows="5"
              name="notificationMessageEn"
              formControlName="notificationMessageEn"
              class="form-control col-md-12"
            ></textarea>
            <div
              class="text-danger col-12 mt-1"
              *ngIf="fSubmited && f.notificationMessageEn.errors?.required"
            >
              {{ "generic.validations.required" | translate }}
            </div>
            <div
              class="text-danger col-12 mt-1"
              *ngIf="fSubmited && f.notificationMessageEn.errors?.minlength"
            >
              {{
                "generic.validations.min_length"
                  | translate
                    : {
                        attr: trans?.notificationMessageEn,
                        min: f.notificationMessageEn.errors?.minlength
                          ?.requiredLength
                      }
              }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </card-body>
</card>
<div class="row buttons-content">
  <div class="col-md-3">
    <button
      [routerLink]="['/configuracao-monitoramento']"
      class="col-md-12 btn btn-outline-primary"
      translate
    >
      generic.buttons.cancel
    </button>
  </div>
  <div class="col-md-3">
    <button
      class="col-md-12 btn btn-primary"
      type="submit"
      (click)="onSubmit()"
      translate
    >
      generic.buttons.save
    </button>
  </div>
</div>
