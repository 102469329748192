<card>
  <card-header>
    <div class="container header-content">
      <div class="row d-flex align-items-center">
        <div class="col-md-9" translate>
          monitoring.form.title_search_monitoring
        </div>
        <div class="col-md-3 d-flex button-header-content">
          <button
            class="btn btn-primary"
            [routerLink]="['novo-registro']"
            translate
          >
            monitoring.new_record
          </button>
        </div>
      </div>
    </div>
  </card-header>

  <card-body>
    <div class="container">
      <form [formGroup]="filterForm" (ngSubmit)="filterHandler()">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label translate
              >monitoring.form.labels.verify_frequency_type</label
            >
            <select
              class="form-control form-control-sm"
              name="unitMeasureId"
              formControlName="unitMeasureId"
            >
              <option [ngValue]="null" translate>generic.all</option>
              <option *ngFor="let op of unitMeasuresForFilters" [value]="op.id">
                {{ op.unitMeasureDescription }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label translate>monitoring.form.labels.action</label>
            <select
              class="form-control form-control-sm"
              name="actionsItemsId"
              formControlName="actionsItemsId"
            >
              <option [ngValue]="null" translate>generic.all</option>
              <option *ngFor="let op of actionsForFilters" [value]="op.id">
                {{ op.actionDescription }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label translate>monitoring.notification_message</label>
            <input
              type="text"
              class="form-control form-control-sm"
              name="notificationMessage"
              formControlName="notificationMessage"
            />
          </div>
        </div>
        <div class="form-row d-flex button-content">
          <div class="form-group col-md-3">
            <button
              (click)="clearFilterHandler($event)"
              class="col-md-12 btn btn-outline-primary"
              translate
            >
              manage_acccess_request.edit.history.clear
            </button>
          </div>
          <div class="form-group col-md-3">
            <button type="submit" class="col-md-12 btn btn-primary" translate>
              generic.buttons.search
            </button>
          </div>
        </div>
      </form>
      <hr />
    </div>
    <div class="container">
      <div class="table-responsive-sm">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="align-middle" translate>
                monitoring.frequancy_limit
              </th>
              <th scope="col" class="align-middle" translate>
                monitoring.verify_frequency_type
              </th>
              <th scope="col" class="align-middle" translate>
                monitoring.action_selected
              </th>
              <th scope="col" class="align-middle" translate>
                generic.buttons.actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let m of monitoringItems">
              <td>{{ m?.maximumQuantity }}</td>
              <td>{{ m?.frequencyCheck }} {{ m?.unitMeasureDescription }}</td>
              <td>{{ m?.actionsItemsDescription }}</td>
              <td class="link">
                <a
                  [routerLink]="[m.id, 'editar']"
                  class="text-dark mr-2 btn-xs"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                    />
                  </svg>
                </a>
                <a (click)="delete(m?.id)" class="text-dark btn-xs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                    />
                  </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </card-body>
</card>
