<hr />
<p class="p-black" translate>search_by_bale.hvi_information</p>
<div class="row">
  <div class="col">
    <label translate> search_by_bale.mic_index </label>
    <p *ngIf="hviInformation.mic != null; else noData">
      {{ hviInformation.mic }}
    </p>
  </div>

  <div class="col">
    <label translate> search_by_bale.mat_index </label>
    <p *ngIf="hviInformation.mat != null; else noData">
      {{ hviInformation.mat }}
    </p>
  </div>

  <div class="col">
    <label translate> search_by_bale.uhml_inch </label>
    <p *ngIf="hviInformation.pol != null; else noData">
      {{ hviInformation.pol | number : "1.3" : "en" }}
    </p>
  </div>

  <div class="col">
    <label translate> UHML* (mm) </label>
    <p *ngIf="hviInformation.mm != null; else noData">
      {{ hviInformation.mm }}
    </p>
  </div>

  <div class="col">
    <label translate> search_by_bale.sfi_inch </label>
    <p *ngIf="hviInformation.sfiPol != null; else noData">
      {{ hviInformation.sfiPol | number : "2.1" : "en" }}
    </p>
  </div>
</div>

<div class="row row-gray-color">
  <div class="col">
    <label translate> UI* (%) </label>
    <p *ngIf="hviInformation.ui != null; else noData">
      {{ hviInformation.ui }}
    </p>
  </div>

  <div class="col">
    <label translate> Str* (g/tex) </label>
    <p *ngIf="hviInformation.str != null; else noData">
      {{ hviInformation.str }}
    </p>
  </div>

  <div class="col">
    <label translate> Elg (%) </label>
    <p *ngIf="hviInformation.elg != null; else noData">
      {{ hviInformation.elg | number : "2.1" : "en" }}
    </p>
  </div>

  <div class="col">
    <label translate> search_by_bale.rd_grade </label>
    <p *ngIf="hviInformation.rd != null; else noData">
      {{ hviInformation.rd }}
    </p>
  </div>

  <div class="col">
    <label translate> search_by_bale.b_grade </label>
    <p *ngIf="hviInformation.b != null; else noData">
      {{ hviInformation.b | number : "2.1" : "en" }}
    </p>
  </div>
</div>

<div class="row">
  <div class="col">
    <label translate> C Grg (HVI UpLand) </label>
    <p *ngIf="hviInformation.cgrdHvi != null; else noData">
      {{ hviInformation.cgrdHvi }}
    </p>
  </div>

  <div class="col">
    <label translate> Tr Cnt </label>
    <p *ngIf="hviInformation.trCnt != null; else noData">
      {{ hviInformation.trCnt | number : "3.0" : "en" }}
    </p>
  </div>

  <div class="col">
    <label translate> Tr Ar (%) </label>
    <p *ngIf="hviInformation.trAr != null; else noData">
      {{ hviInformation.trAr | number : "2.2" : "en" }}
    </p>
  </div>

  <div class="col">
    <label translate> search_by_bale.trld_leafgrd_grade </label>
    <p *ngIf="hviInformation.trId != null; else noData">
      {{ hviInformation.trId }}
    </p>
  </div>

  <div class="col">
    <label translate> search_by_bale.sci_index </label>
    <p *ngIf="hviInformation.sci != null; else noData">
      {{ hviInformation.sci }}
    </p>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <p>
      <small>
        <div class="row text-responsive" translate>
          search_by_bale.current_norms
        </div>
      </small>
    </p>
  </div>
</div>
<ng-template #noData>
  <p class="d-flex ml-5 text-responsive">-</p>
</ng-template>
<hr />
