import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ReportsService } from 'src/app/core/services/reports.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { Base64ToBlob } from 'src/app/shared/utils/base64ToBlob';
import FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalService } from 'src/app/core/services/alert-modal.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'rastreabilidade-trading-requester',
  templateUrl: './trading-requester.component.html',
  styleUrls: ['./trading-requester.component.scss'],
})
export class TradingRequesterComponent implements OnInit {
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  currentPageSize = 10;
  isLoading: boolean = false;
  tradingsRequesters: any[] = [];
  searchedBurden: number = 0;
  isBlocked: boolean = false;
  requestersList: any;
  companyList: any;
  tradeList: any;
  bsConfig?: Partial<BsDatepickerConfig>;

  formFilter = this.fb.group({
    companyRequester: [null],
    trading: [''],
    requesterId: [''],
    isForeign: [''],
    queryType: [''],
    status: [''],
    initPeriod: [''],
    finishPeriod: [''],
    initDateInput: [''],
    finishDateInput: [''],
  });

  width: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.width = window.innerWidth;
  }

  constructor(
    private service: ReportsService,
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private modal: AlertModalService,
    private config: NgbTypeaheadConfig,
    private dateLocale: BsLocaleService
  ) {
    this.dateLocale.use(localStorage.getItem('locale'));
  }

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });
    this.width = window.innerWidth;

    this.formFilter.get('initDateInput').valueChanges.subscribe((ret) => {
      let date =
        ret != ''
          ? `${ret?.getFullYear()}-${('0' + (ret?.getMonth() + 1)).slice(
              -2
            )}-${('0' + ret?.getDate()).slice(-2)}`
          : '';
      this.formFilter.controls.initPeriod.setValue(date);
    });

    this.formFilter.get('finishDateInput').valueChanges.subscribe((ret) => {
      let date =
        ret != ''
          ? `${ret?.getFullYear()}-${('0' + (ret?.getMonth() + 1)).slice(
              -2
            )}-${('0' + ret?.getDate()).slice(-2)}`
          : '';
      this.formFilter.controls.finishPeriod.setValue(date);
    });

    this.refreshPage();
  }

  formatterCompany(x: { value: string }) {
    return x.value;
  }

  formatterRequester(x: { value: string }) {
    return x.value;
  }

  formatterTrade(x: { value: string }) {
    return x.value;
  }

  setValueForm(event: any, selectedControl: string, input: any = null) {
    switch (selectedControl) {
      case 'companyRequester':
        this.formFilter.controls.companyRequester.setValue(event.item.key);
        break;
      case 'requesterId':
        this.formFilter.controls.requesterId.setValue(event.item.key);
        break;
      case 'trading':
        this.formFilter.controls.trading.setValue(event.item.key);
        break;
      default:
        event.preventDefault();
        input.value = '';
        break;
    }
  }

  searchRequester = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      map((term) => (term.length < 2 ? [] : this.getRequester(term)))
    );

  getRequester(param: string) {
    if (this.formFilter.controls.companyRequester.value) {
      param =
        param + '?CompanyId=' + this.formFilter.controls.companyRequester.value;
    }
    this.service.getRequester(param).subscribe((res) => {
      if (res.data.length > 0) this.requestersList = res.data;
      else
        this.requestersList = [
          { key: null, value: 'nenhum resultado encontrado' },
        ];
    });
    return this.requestersList;
  }

  searchCompany = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      map((term) => (term.length < 2 ? [] : this.getCompany(term)))
    );

  getCompany(param: string) {
    this.service.getCompany(param).subscribe((res) => {
      if (res.data.length > 0) this.companyList = res.data;
      else
        this.companyList = [
          { key: null, value: 'nenhum resultado encontrado' },
        ];
    });
    return this.companyList;
  }

  searchTrade = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      map((term) => (term.length < 2 ? [] : this.getTrade(term)))
    );

  getTrade(param: string) {
    this.service.getTrade(param).subscribe((res) => {
      if (res.data.length > 0) this.tradeList = res.data;
      else
        this.tradeList = [{ key: null, value: 'nenhum resultado encontrado' }];
    });
    return this.tradeList;
  }

  setDate() {}

  refreshPage(): void {
    this.showSpinner();
    if (!this.validateDate()) return;
    this.getAllProducerRequester();
  }

  clearFilter(event, inputCompany, inputRequester, inputTrading) {
    this.setValueForm(event, '', inputCompany);
    this.setValueForm(event, '', inputRequester);
    this.setValueForm(event, '', inputTrading);

    this.formFilter?.controls?.trading?.setValue('');
    this.formFilter?.controls?.companyRequester?.setValue(null);
    this.formFilter?.controls?.requesterId?.setValue('');
    this.formFilter?.controls?.queryType?.setValue('');
    this.formFilter?.controls?.isForeign?.setValue('');
    this.formFilter?.controls?.status?.setValue('');
    this.formFilter?.controls?.initDateInput?.setValue('');
    this.formFilter?.controls?.finishDateInput?.setValue('');
    this.formFilter?.controls?.initPeriod?.setValue('');
    this.formFilter?.controls?.finishPeriod?.setValue('');

    this.refreshPage();
  }

  showSpinner() {
    this.isLoading = true;
    this.spinnerService.show();
  }

  hideSpinner() {
    this.isLoading = false;
    this.spinnerService.hide();
  }

  validateDate(): boolean {
    if (
      this.formFilter.controls.initPeriod.value >
      this.formFilter.controls.finishPeriod.value
    ) {
      this.toastService.showToast(
        ToastTypeEnum.DANGER,
        'Erro',
        `Data de início do período maior que a data fim.`
      );
      this.spinnerService.hide();
      return false;
    }

    return true;
  }

  getAllProducerRequester() {
    this.service
      .getAllTradingRequester(
        { ...this.formFilter.getRawValue() },
        this.page,
        this.pageSize
      )
      .subscribe(
        (res) => {
          this.tradingsRequesters = res.data?.reports;
          this.currentPageSize = res.data?.reports?.length;
          this.collectionSize = res.data?.total;
          this.searchedBurden = res.data?.totalBurdensRequest;

          this.hideSpinner();
        },
        (errors) => {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            `${errors}`
          );
        }
      );
  }

  downloadArquivoCsv() {
    this.showSpinner();
    this.service
      .downloadCsvTradingRequester({ ...this.formFilter.getRawValue() })
      .subscribe((res) => {
        if (res.success && res.data) {
          const blob = new Base64ToBlob().convertToBlob(res.data, 'text/csv');
          FileSaver.saveAs(blob, `relatorio`);
        } else {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            'Erro ao baixar o certificado'
          );
        }
        this.spinnerService.hide();
      });
  }

  block(email: string) {
    this.showSpinner();
    this.service.blockUser(email).subscribe((res) => {
      if (res.success) {
        this.toastService.showToast(
          ToastTypeEnum.SUCCESS,
          'Sucesso',
          'Sucesso ao bloquear'
        );

        this.refreshPage();
      } else {
        this.toastService.showToast(
          ToastTypeEnum.DANGER,
          'Erro',
          'Erro ao bloquear'
        );
      }
      this.spinnerService.hide();
    });
  }

  unlock(email: string) {
    this.showSpinner();
    this.service.unlockUser(email).subscribe((res) => {
      if (res.success) {
        this.toastService.showToast(
          ToastTypeEnum.SUCCESS,
          'Sucesso',
          'Sucesso ao desbloquear'
        );

        this.refreshPage();
      } else {
        this.toastService.showToast(
          ToastTypeEnum.DANGER,
          'Erro',
          'Erro ao desbloquear'
        );
      }
      this.spinnerService.hide();
    });
  }

  notifyUser(email: string) {
    this.showSpinner();
    this.service.notifyUser(email).subscribe((res) => {
      if (res.success) {
        this.toastService.showToast(
          ToastTypeEnum.SUCCESS,
          'Sucesso',
          'Sucesso ao notificar o usuário'
        );

        this.refreshPage();
      } else {
        this.toastService.showToast(
          ToastTypeEnum.DANGER,
          'Erro',
          'Erro ao notificar o usuário'
        );
      }
      this.spinnerService.hide();
    });
  }

  GenerateFileFrequency(trading: string, id: string) {
    this.showSpinner();
    var queryType = this.formFilter.controls.queryType.value;
    let param = `${trading}/${id}/${queryType}`;
    this.service.generateFileFrequencyTrading(param).subscribe((res) => {
      if (res.success && res.data) {
        const blob = new Base64ToBlob().convertToBlob(res.data, 'text/plain');
        FileSaver.saveAs(blob, `File Frequency`);
      } else {
        this.toastService.showToast(
          ToastTypeEnum.DANGER,
          'Erro',
          'Erro ao baixar o certificado'
        );
      }
      this.spinnerService.hide();
    });
  }

  GenerateFileSequency(trading: string, id: string) {
    this.showSpinner();
    var queryType = this.formFilter.controls.queryType.value;
    let param = `${trading}/${id}/${queryType}`;
    this.service.generateFileSequencyTrading(param).subscribe((res) => {
      if (res.success && res.data) {
        const blob = new Base64ToBlob().convertToBlob(res.data, 'text/plain');
        FileSaver.saveAs(blob, `File Frequency`);
      } else {
        this.toastService.showToast(
          ToastTypeEnum.DANGER,
          'Erro',
          'Erro ao baixar o certificado'
        );
      }
      this.spinnerService.hide();
    });
  }

  async showFrequency(tradingId: string, userId: string, code: number) {
    this.showSpinner();

    this.service
      .getTradingFrequency(tradingId, userId, code)
      .subscribe(async (res) => {
        this.hideSpinner();

        await this.modal
          .showFrequencyModal(
            res.data.requester,
            res.data.request,
            res.data.burdens
          )
          .pipe(
            map((data) => {
              if (data) {
                this.showSpinner();

                this.service
                  .downloadTradingFrequencyCsv(tradingId, userId, code)
                  .subscribe((res) => {
                    if (res.success && res.data) {
                      const blob = new Base64ToBlob().convertToBlob(
                        res.data,
                        'text/csv'
                      );
                      FileSaver.saveAs(blob, `relatorio-frequencia`);
                    } else {
                      this.toastService.showToast(
                        ToastTypeEnum.DANGER,
                        'Erro',
                        'Erro ao baixar o relatorio de frequencia'
                      );
                    }

                    this.hideSpinner();
                  });
              }
            })
          )
          .toPromise();
      });
  }

  async showSequence(tradingId: string, userId: string, code: number) {
    this.showSpinner();

    this.service
      .getTradingSequence(tradingId, userId, code)
      .subscribe(async (res) => {
        this.hideSpinner();

        await this.modal
          .showSequenceModal(
            res.data.requester,
            res.data.request,
            res.data.burdens
          )
          .pipe(
            map((data) => {
              if (data) {
                this.showSpinner();

                this.service
                  .downloadTradingSequenceCsv(tradingId, userId, code)
                  .subscribe((res) => {
                    if (res.success && res.data) {
                      const blob = new Base64ToBlob().convertToBlob(
                        res.data,
                        'text/csv'
                      );
                      FileSaver.saveAs(blob, `relatorio-sequencia`);
                    } else {
                      this.toastService.showToast(
                        ToastTypeEnum.DANGER,
                        'Erro',
                        'Erro ao baixar o relatorio de frequencia'
                      );
                    }

                    this.hideSpinner();
                  });
              }
            })
          )
          .toPromise();
      });
  }
}
