<card>
  <card-header>
    <div class="container title-content" translate>
      reports.requester.filtering_information_for_reporting
    </div>
  </card-header>

  <card-body>
    <div class="container">
      <form [formGroup]="formFilter">
        <div class="row">
          <div class="form-group col-md-5">
            <label for="search__type" class="lbl__thinner" translate>
              reports.requester.query_type
            </label>
            <select
              formControlName="queryType"
              class="form-control form-control-sm"
              id="search__type"
            >
              <option value="1" translate>
                reports.requester.individual_consultation
              </option>
              <option value="0" translate>reports.requester.batch_query</option>
            </select>
          </div>
          <div class="col-md-2"></div>
          <div
            class="form-group col-md-5"
            *ngIf="formFilter.controls.queryType.value == 0"
          >
            <label for="resquest__status" class="lbl__thinner" translate>
              reports.requester.request_status
            </label>
            <select
              formControlName="statusRequest"
              class="form-control form-control-sm"
              id="resquest__status"
            >
              <option value="" translate>reports.requester.all</option>
              <option value="1" translate>reports.requester.in_progress</option>
              <option value="2" translate>reports.requester.completed</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <br />
            <label for="search__period" class="lbl__thinner" translate>
              generic.search.research_period
            </label>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-5">
            <label for="period__begin" class="lbl__thinner" translate>
              generic.search.start_period
            </label>
            <div class="input-group">
              <input
                type="text"
                formControlName="initDateInput"
                placeholder="{{ 'GENERIC.DATE_INPUT_START' | translate }}"
                class="form-control"
                bsDatepicker
                [bsConfig]="bsConfig"
              />
            </div>
          </div>
          <div class="col-md-2 d-flex justify-content-center">
            <label for="" translate>generic.search.until</label>
          </div>
          <div class="form-group col-md-5">
            <label for="period__end" class="lbl__thinner" translate>
              generic.search.end_period
            </label>
            <div class="input-group">
              <input
                type="text"
                formControlName="finishDateInput"
                placeholder="{{ 'GENERIC.DATE_INPUT_END' | translate }}"
                class="form-control"
                bsDatepicker
                [bsConfig]="bsConfig"
              />
            </div>
          </div>
        </div>
        <div class="row d-flex button-content">
          <div class="form-row d-flex aling_end">
            <div class="form-group col">
              <button
                type="submit"
                class="btn btn-outline-primary col-md-9"
                (click)="clearFilter()"
                translate
              >
                generic.buttons.clean
              </button>
            </div>
            <div class="form-group col">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="refreshPage()"
                translate
              >
                generic.buttons.search
              </button>
            </div>
          </div>
        </div>
      </form>
      <hr />
      <div class="search__result__labels">
        <label class="range__label">
          <label *ngIf="formFilter?.controls.startPeriod.value !== null">
            {{ "generic.search.period" | translate }} :
            {{ formFilter?.controls.startPeriod.value | date : "shortDate" }}
          </label>
          <label *ngIf="formFilter?.controls.endPeriod.value !== null">
            &nbsp;{{ "generic.search.to" | translate }}
            {{ formFilter?.controls.endPeriod.value | date : "shortDate" }}
          </label>
        </label>
        <label class="total__result">
          {{ "generic.search.totalizer" | translate }}: {{ searchedBurden }}
          {{ "generic.search.bales_consulted" | translate }}
        </label>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th translate>REPORTS.REQUESTER.BALE_CODE</th>
              <th translate>reports.requester.query_request_date</th>
              <th translate>reports.requester.query_type</th>
              <th translate>reports.requester.date_opinion</th>
              <th translate>reports.requester.quantity_of_bales_consulted</th>
              <th translate>reports.requester.table.status</th>
              <th colspan="2" translate>reports.requester.generated_files</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="requesterReports.length == 0">
              <td colspan="7">Nenhum dado encontrado!</td>
            </tr>
            <tr *ngFor="let requesterReport of requesterReports; translate">
              <td>{{ requesterReport?.code }}</td>
              <td>{{ requesterReport?.solicitacaoConsulta }}</td>
              <td>{{ requesterReport?.tipoConsulta }}</td>
              <td>
                {{
                  requesterReport?.parecer != null
                    ? requesterReport?.parecer
                    : "-"
                }}
              </td>
              <td>{{ requesterReport?.quantidadeFardosConsultados }}</td>
              <td>{{ requesterReport?.statusSolicitacaoConsulta }}</td>
              <td
                style="border-right: 0"
                *ngIf="requesterReport?.canDownload == true"
              >
                <app-download
                  type="cloud-download-alt"
                  label="{{ 'generic.buttons.download' | translate }}"
                  (onClick)="
                    downloadTxtFile(
                      requesterReport?.generateArchiveBurdenRequestViewModel
                    )
                  "
                >
                </app-download>
              </td>
              <td
                style="border-left: 0"
                *ngIf="requesterReport?.canDownload == true"
              >
                <app-download
                  type="file-pdf"
                  label="{{ 'generic.buttons.download' | translate }}"
                  (onClick)="
                    downloadPdfFile(
                      requesterReport?.generateArchiveBurdenRequestViewModel
                    )
                  "
                >
                </app-download>
              </td>
              <td colspan="2" *ngIf="requesterReport?.canDownload == false">
                <label class="file-download"> - - </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex-lg-row p-2 pagination-content">
        <div class="show-page-content">
          <div class="label-content">
            <label translate>GENERIC.PAGINATION.LABEL.SHOWING</label>
            <label>{{ currentPageSize }}</label>
          </div>
          <div class="label-content">
            <label translate>GENERIC.PAGINATION.LABEL.REGISTERS</label>
            <label>{{ collectionSize }}</label>
          </div>
        </div>
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [maxSize]="width > 767 ? 5 : 3"
          [rotate]="true"
          [pageSize]="pageSize"
          (pageChange)="refreshPage()"
          class="d-flex d-lg-block justify-content-center"
        >
        </ngb-pagination>
        <div class="page-size d-flex justify-content-center align-items-center">
          <select
            class="custom-select"
            style="width: auto"
            [(ngModel)]="pageSize"
            (ngModelChange)="refreshPage()"
          >
            <option [ngValue]="10" translate>
              10
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="25" translate>
              25
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="50" translate>
              50
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="100" translate>
              100
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-12 footer-content">
        <h6>
          <app-download
            [type]="'file-csv'"
            label="{{ 'generic.buttons.download_csv' | translate }}"
            [direction]="'row'"
            (onClick)="downloadArquivoCsv()"
          >
          </app-download>
        </h6>
      </div>
      <br />
    </div>
  </card-body>
</card>
